import {
  defaultImage,
  formatCurrency,
  StoreProduct,
  colors,
} from '@bofrak-backend/shared';
import {
  Badge,
  Box,
  BoxProps,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { uniq } from 'lodash';
import { ProductCheckboxCard } from './product-check-box';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useCart } from '../../hooks/use-cart';
import { productsAtom } from '../../recoil/atoms';

interface SelectChildProps {
  product: StoreProduct;
}

export const SelectChild = ({ product }: SelectChildProps) => {
  const { addItem, cartData, deleteItem, updateItemQuantity } = useCart();
  const products = useRecoilValue(productsAtom);
  const [storeProducts, setStoreProducts] = useState<StoreProduct[]>([]);

  // Get the store_product_fractions IDs, including the parent product ID
  const store_product_fractions: string[] = uniq([
    product.id,
    ...product.store_product_fractions.map((fraction) => fraction.id),
  ]);

  useEffect(() => {
    if (products) {
      const filteredProducts = products.filter((product) =>
        store_product_fractions.includes(product.id),
      );
      setStoreProducts(filteredProducts);
    }
  }, [products]);

  return (
    <Box width="full" height={'full'} as="section" bg="bg.surface" py="5">
      <Card>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '4', md: '10' }}>
          <VStack>
            <Text
              width={'full'}
              size="lg"
              textAlign={'center'}
              fontWeight="bold"
              letterSpacing="tight">
              {product.name}
            </Text>
            <Image
              src={product.image}
              alt={product.name}
              maxW="150px"
              draggable="false"
              fallback={
                <Image maxW="150px" src={defaultImage} objectFit={'contain'} />
              }
            />

            <Text
              size="lg"
              fontWeight={'medium'}
              textAlign={'center'}
              letterSpacing="tight">
              {product.inventory.toFixed(2)} in Stock
            </Text>
          </VStack>

          <CardContent>
            <Box as="section" bg="bg.surface" py={{ base: '4', md: '8' }}>
              <VStack maxW="lg">
                {storeProducts?.map((option) => {
                  const cartItem = cartData.find(
                    (item) => item.item_id === option.id,
                  );

                  const quantity = cartItem?.quantity ?? 0;

                  return (
                    <ProductCheckboxCard
                      quantity={quantity}
                      lineQuantity={cartItem?.line_quantity ?? 0}
                      addItem={addItem}
                      deleteItem={deleteItem}
                      isInCart={!!cartItem}
                      product={option}
                      cartId={cartItem?.id ?? ''}
                      updateItemQuantity={updateItemQuantity}
                      key={option.id}
                      value={option.id}>
                      {option.is_sold_by_weight ? (
                        <VStack>
                          <Text
                            textAlign={'center'}
                            width={'full'}
                            color="fg.emphasized"
                            fontSize="xs">
                            {option.name}{' '}
                            {option.unit ? `(${option.unit})` : ''}
                          </Text>
                          <HStack>
                            <Text
                              fontSize={'lg'}
                              color="fg.emphasized"
                              fontWeight="bold">
                              {formatCurrency(option.price)}
                            </Text>
                            <Badge color={'white'} bg={colors.green}>
                              {' '}
                              {formatCurrency(option.cost)}
                            </Badge>
                          </HStack>
                        </VStack>
                      ) : (
                        <HStack justify={'space-between'} width={'full'} p="1">
                          <Text
                            color="fg.emphasized"
                            textOverflow={'ellipsis'}
                            fontSize="md">
                            {option.name}{' '}
                            {option.unit ? `(${option.unit})` : ''}
                          </Text>

                          <VStack justify={'space-between'}>
                            {quantity > 0 ? (
                              <Text
                                fontSize={'lg'}
                                color="fg.emphasized"
                                fontWeight="bold">
                                {cartItem?.line_quantity} &times;{' '}
                                {formatCurrency(option.price)}
                              </Text>
                            ) : (
                              <Text
                                fontSize={'lg'}
                                color="fg.emphasized"
                                fontWeight="bold">
                                {formatCurrency(option.price)}
                              </Text>
                            )}
                            <Badge color={'white'} bg={colors.green}>
                              {' '}
                              {formatCurrency(option.cost)}
                            </Badge>
                          </VStack>
                        </HStack>
                      )}
                    </ProductCheckboxCard>
                  );
                })}
              </VStack>
            </Box>
          </CardContent>
        </Stack>
      </Card>
    </Box>
  );
};

const CardContent = (props: BoxProps) => <Box width="full" {...props} />;

const Card = (props: BoxProps) => (
  <Box
    mx="auto"
    height={'full'}
    p={{ base: '6', md: '8' }}
    rounded={{ sm: 'lg' }}
    {...props}
  />
);
