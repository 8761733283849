import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Spacer,
  VisuallyHidden,
} from '@chakra-ui/react';

import { colors } from '@bofrak-backend/shared';
import { useNetworkState } from '@uidotdev/usehooks';
import {
  MdMenu,
  MdSignalWifi2Bar,
  MdSignalWifi3Bar,
  MdSignalWifi4Bar,
  MdSignalWifiOff,
  MdWifiOff,
} from 'react-icons/md';
import { useSetRecoilState } from 'recoil';
import { searchQueryAtom } from '../../recoil/atoms';
import AppName from '../app-name';
import ShoppingCartWithCount from '../cart/shopping-cart-with-count';
import { SearchInput } from '../search-input';
import { useCart } from '../../hooks/use-cart';

interface MainHeaderProps {
  openNav: () => void;
  openCart: () => void;
}

export const MainHeader = ({ openNav, openCart }: MainHeaderProps) => {
  const networkState = useNetworkState();
  const { numberOfItemsInCart } = useCart();
  const setQuery = useSetRecoilState(searchQueryAtom);

  const getWifiIcon = () => {
    const { effectiveType, online } = networkState;
    if (!online) {
      return (
        <Icon color={colors.red} fontSize="20px">
          <MdSignalWifiOff />
        </Icon>
      );
    }

    if (effectiveType === 'slow-2g')
      return (
        <Icon color={colors.red} fontSize="20px">
          <MdWifiOff />
        </Icon>
      );
    if (effectiveType === '2g')
      return (
        <Icon color={colors.green} fontSize="20px">
          <MdSignalWifi2Bar />
        </Icon>
      );
    if (effectiveType === '3g')
      return (
        <Icon color={colors.green} fontSize="20px">
          <MdSignalWifi3Bar />
        </Icon>
      );
    return (
      <Icon color={colors.green} fontSize="20px">
        <MdSignalWifi4Bar />
      </Icon>
    );
  };

  return (
    <>
      <Flex
        direction="column"
        overflow="hidden"
        width={'full'}
        display={{ base: 'flex', lg: 'none' }}>
        <Box px="4" py="4" overflow="auto">
          <Flex
            align="center"
            justify="space-between"
            mb="3"
            display={{ base: 'flex', lg: 'none' }}>
            <HStack width={'full'} spacing="3">
              <Center w="8" h="8" as="button" type="button">
                <VisuallyHidden>Toggle Menu</VisuallyHidden>
                <Box as={MdMenu} fontSize="3xl" onClick={openNav} />
              </Center>
              <Spacer />
              <AppName />
              <Spacer />
              <Box>{getWifiIcon()}</Box>
              <ShoppingCartWithCount
                count={numberOfItemsInCart}
                openCart={openCart}
              />
            </HStack>
          </Flex>
          <SearchInput setQuery={setQuery} />
        </Box>
      </Flex>

      <HStack display={{ base: 'none', lg: 'inherit' }} width={'full'}>
        <AppName />
        <SearchInput setQuery={setQuery} mx={'30px'} />

        <HStack justify="center" align="center">
          {getWifiIcon()}
          <ShoppingCartWithCount
            count={numberOfItemsInCart}
            openCart={openCart}
          />
        </HStack>
      </HStack>
    </>
  );
};
