// SyncData.tsx
import { Alert, AlertIcon, Stack, Text } from '@chakra-ui/react';
import { useNetworkState } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useWorker } from '../../hooks/use-web-worker';
import {
  storeAtom,
  triggerRefetchAtom,
  workerActionsAtom,
} from '../../recoil/atoms';
import { WebWorkerActions } from '../../utils/types';

// Entity configuration type
export interface SyncEntityConfig {
  name: string;
  action: WebWorkerActions;
  backendUrl: string;
  worker: new () => Worker;
  should_sync_offline?: boolean;
}

interface SyncDataProps {
  entity: SyncEntityConfig;
}

function SyncData({ entity }: SyncDataProps) {
  const { online } = useNetworkState();
  const store = useRecoilValue(storeAtom);
  const actions = useRecoilValue(workerActionsAtom);
  const setTriggerRefetch = useSetRecoilState(triggerRefetchAtom);

  const { name, action, backendUrl, worker, should_sync_offline } = entity;

  // Worker hook for the specific entity
  const { workerStatus, startWorker } = useWorker(worker);

  // State to control visibility of the alerts
  const [visibleAlertStatus, setVisibleAlertStatus] = useState<string | null>(
    null,
  );
  const [alertMessage, setAlertMessage] = useState<string>('');

  useEffect(() => {
    if (!actions.includes(action)) return;

    if (online) {
      startWorker({
        action,
        payload: { backendUrl },
      });
    } else {
      if (should_sync_offline) {
        startWorker({
          action,
          payload: { backendUrl },
        });
      }
    }
  }, [online, backendUrl, store, startWorker, actions]);

  useEffect(() => {
    if (workerStatus.status) {
      // Set the alert message based on the status
      let message = '';
      switch (workerStatus.status) {
        case 'idle':
          message = `Waiting for internet connection when syncing ${name}...`;
          break;
        case 'running':
          message = `Syncing ${name}...`;
          break;
        case 'progress':
          message = workerStatus.message || `Syncing ${name}...`;
          break;
        case 'success':
          message = `${name} synced successfully`;
          setTriggerRefetch((prev) => !prev);
          break;
        case 'error':
          message = `Error occurred syncing ${name}`;
          console.error('Worker Error:', workerStatus);
          break;
        default:
          message = '';
      }

      setAlertMessage(message);
      setVisibleAlertStatus(workerStatus.status);

      // Hide the alert after 10 seconds
      const timer = setTimeout(() => {
        setVisibleAlertStatus(null);
      }, 10000);

      // Cleanup the timer on unmount or when workerStatus.status changes
      return () => clearTimeout(timer);
    }
  }, [workerStatus.status]);

  return (
    <Stack spacing="3">
      {visibleAlertStatus && (
        <Alert
          p="1"
          status={
            visibleAlertStatus === 'error'
              ? 'error'
              : visibleAlertStatus === 'success'
                ? 'success'
                : 'info'
          }
          size="xs">
          <AlertIcon boxSize="4" />
          <Text fontSize="xs">{alertMessage}</Text>
        </Alert>
      )}
    </Stack>
  );
}

export default SyncData;
