// useCart.ts
import {
  Customer,
  Discount,
  DiscountCalculator,
  DiscountType,
  ReasonMissing,
  ReceiptPayment,
  StoreProduct,
  Tax,
  TaxCalculator,
  TaxType,
} from '@bofrak-backend/shared';
import _, { unionBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ulid } from 'ulidx';
import { db } from '../api/local';
import {
  cartItemsAtom,
  currentCartIdAtom,
  discountsAtom,
  storeAtom,
  taxesAtom,
} from '../recoil/atoms';
import { CartItemInterface, CustomerCart } from '../utils/types';

const allowDiscounts = false;

export const useCart = () => {
  const [cartData, setCartData] = useRecoilState(cartItemsAtom);
  const [currentCartId, setCurrentCartId] = useRecoilState(currentCartIdAtom);
  const store = useRecoilValue(storeAtom);
  const taxes = useRecoilValue(taxesAtom);
  const discounts = useRecoilValue(discountsAtom);
  const [change, setChange] = useState(0); // State for excess payment amount

  // Find the current cart based on currentCartId
  const currentCart = cartData.find((cart) => cart.cart_id === currentCartId);
  const numberOfItemsInCart = currentCart
    ? currentCart.cart_items.reduce((acc, item) => acc + item.line_quantity, 0)
    : 0;

  // Recalculate taxes and discounts whenever the current cart changes
  useEffect(() => {
    calculateTaxesAndDiscountsInCurrentCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCart]);

  // Discount calculation helper
  const applyDiscountsToCartItem = (
    cartItem: CartItemInterface,
    additionalDiscounts: Discount[],
    pointsDiscount: number,
  ) => {
    const lineOriginalPrice = cartItem.original_price * cartItem.line_quantity;

    // Combine discounts from the product and any additional cart discounts

    const allDiscounts = allowDiscounts
      ? _.uniqBy(
          [
            ...(cartItem.product_discounts || []),
            ...(additionalDiscounts || []),
          ],
          'id',
        )
      : [];

    // Calculate line discounts
    const lineDiscounts = allDiscounts.map((discount) => {
      const { type, discount_amount = 0, discount_percent = 0 } = discount;
      let discountAmount = 0;
      switch (type) {
        case DiscountType.FIXED_AMOUNT:
          discountAmount =
            DiscountCalculator.calculateFixedDiscount(
              lineOriginalPrice,
              discount_amount,
            ).discount_value || 0;
          break;
        case DiscountType.FIXED_PERCENT:
          discountAmount =
            DiscountCalculator.calculatePercentageDiscount(
              lineOriginalPrice,
              discount_percent,
            ).discount_value || 0;
          break;
        default:
          break;
      }
      return { ...discount, money_amount: discountAmount };
    });

    // Sum total discount for the item
    const totalDiscount =
      lineDiscounts.reduce(
        (acc, discount) => acc + (discount.money_amount || 0),
        0,
      ) + pointsDiscount;

    // Calculate final price after applying discounts
    const priceAfterDiscount = Math.max(lineOriginalPrice - totalDiscount, 0);

    return { lineDiscounts, totalDiscount, priceAfterDiscount };
  };

  // Tax calculation helper
  const applyTaxesToCartItem = (
    cartItem: CartItemInterface,
    priceAfterDiscount: number,
  ) => {
    const allTaxes: Tax[] = unionBy(
      [...cartItem.added_product_taxes, ...cartItem.line_taxes],
      'id',
    );

    // Separate added and included taxes
    const addedTaxes = allTaxes.filter((tax) => tax.type === TaxType.ADDED);
    const includedTaxes = allTaxes.filter(
      (tax) => tax.type === TaxType.INCLUDED,
    );

    // Calculate included taxes
    const includedLineTaxes = includedTaxes
      .map((tax) => {
        const taxAmount = TaxCalculator.calculateIncludedTax(
          priceAfterDiscount,
          tax.rate,
        );
        return { ...tax, money_amount: taxAmount.taxValue };
      })
      .filter((tax) => tax.money_amount > 0);

    const totalIncludedTaxes = includedLineTaxes.reduce(
      (acc, tax) => acc + tax.money_amount,
      0,
    );

    // Adjust the price to exclude included taxes
    const priceExcludingIncludedTaxes = priceAfterDiscount - totalIncludedTaxes;

    // Calculate added taxes
    const addedLineTaxes = addedTaxes
      .map((tax) => {
        const taxAmount = TaxCalculator.calculateAddedTax(
          priceExcludingIncludedTaxes,
          tax.rate,
        );
        return { ...tax, money_amount: taxAmount };
      })
      .filter((tax) => tax.money_amount > 0);

    const totalAddedTaxes = addedLineTaxes.reduce(
      (acc, tax) => acc + tax.money_amount,
      0,
    );

    // Combine all taxes
    const lineTaxes = [...includedLineTaxes, ...addedLineTaxes];

    return {
      lineTaxes,
      totalAddedTaxes,
      totalIncludedTaxes,
      priceExcludingIncludedTaxes,
    };
  };

  // Main function to calculate taxes and discounts
  const calculateTaxesAndDiscountsInCurrentCart = useCallback(() => {
    if (!currentCart || !currentCartId) return;

    const pointsDiscount =
      currentCart.cart_items.length && currentCart.customer_points
        ? currentCart.customer_points / currentCart.cart_items.length
        : 0;

    const updatedCartItems = currentCart.cart_items.map((cartItem) => {
      const lineOriginalPrice =
        cartItem.original_price * cartItem.line_quantity;

      // Apply discounts
      const { lineDiscounts, totalDiscount, priceAfterDiscount } =
        applyDiscountsToCartItem(
          cartItem,
          currentCart.additional_discounts,
          pointsDiscount,
        );

      // Apply taxes on the price after discounts
      const { lineTaxes, totalAddedTaxes, totalIncludedTaxes } =
        applyTaxesToCartItem(cartItem, priceAfterDiscount);

      // Calculate total money and gross total money
      const totalMoney = lineOriginalPrice;
      const grossTotalMoney = lineOriginalPrice + totalAddedTaxes;

      return {
        ...cartItem,
        line_original_price: lineOriginalPrice,
        line_discounts: lineDiscounts,
        total_discount: totalDiscount,
        price: priceAfterDiscount,
        line_taxes: lineTaxes,
        total_money: totalMoney,
        gross_total_money: grossTotalMoney,
        total_line_added_taxes: totalAddedTaxes,
        total_line_included_taxes: totalIncludedTaxes,
      };
    });

    // Aggregate totals for the cart
    const totalMoney = updatedCartItems.reduce(
      (acc, item) => acc + item.total_money,
      0,
    );
    const grossTotalMoney = updatedCartItems.reduce(
      (acc, item) => acc + item.gross_total_money,
      0,
    );
    const totalIncludedTaxes = updatedCartItems.reduce(
      (acc, item) => acc + item.total_line_included_taxes,
      0,
    );
    const totalAddedTaxes = updatedCartItems.reduce(
      (acc, item) => acc + item.total_line_added_taxes,
      0,
    );

    const allLineTaxes = _.flatten(
      updatedCartItems.map((item) => item.line_taxes),
    );
    const allLineDiscounts = _.flatten(
      updatedCartItems.map((item) => item.line_discounts),
    );

    const totalDiscounts = allLineDiscounts.reduce(
      (acc, discount) => acc + (discount.money_amount || 0),
      0,
    );

    const updatedCart = {
      ...currentCart,
      cart_items: updatedCartItems,
      total_taxes: allLineTaxes,
      discounts: allLineDiscounts,
      total_money: totalMoney,
      gross_total_money: grossTotalMoney,
      total_included_taxes: totalIncludedTaxes,
      total_added_taxes: totalAddedTaxes,
      total_discounts: totalDiscounts,
    };

    // Update cart data in state if changed
    if (!_.isEqual(currentCart, updatedCart)) {
      setCartData((prevCartData) =>
        prevCartData.map((cart) =>
          cart.cart_id === currentCartId ? updatedCart : cart,
        ),
      );
    }
  }, [currentCart, currentCartId, setCartData]);

  const addItem = useCallback(
    (product: StoreProduct): void => {
      if (!product.is_available_for_sale || !store) return;

      // If there's no current cart, create one
      if (!currentCartId) {
        const newCartId = ulid();
        const newCart: CustomerCart = {
          cart_id: newCartId,
          store_id: store.id,
          store_name: store.name,
          cart_items: [],
          payments: [],
          discounts: [],
          total_taxes: [],
          customer_name: '',
          customer_number: '',
          total_money: 0,
          gross_total_money: 0,
          additional_discounts: [],
          customer_points: 0,
          total_added_taxes: 0,
          total_discounts: 0,
          total_included_taxes: 0,
        };
        setCartData((prev) => [...prev, newCart]);
        setCurrentCartId(newCartId);
      }

      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;

          const existingItemIndex = cart.cart_items.findIndex(
            (item) => item.item_id === product.id,
          );

          if (existingItemIndex !== -1) {
            // Update existing item
            const cartItemItems = cart.cart_items.map((item, index) =>
              index === existingItemIndex
                ? {
                    ...item,
                    line_quantity: item.line_quantity + 1,
                    quantity: item.quantity + item.fraction,
                  }
                : item,
            );
            return { ...cart, cart_items: cartItemItems };
          } else {
            // Add new item
            const newItem: CartItemInterface = {
              id: ulid(),
              item_name: `${product.name} (Unit=${product.quantity_in_unit_package})`,
              price: product.price, // Will be recalculated
              line_quantity: 1,
              original_cost: product.cost,
              original_price: product.price,
              image_url: product.image,
              currency: 'MZN',
              fraction: product.fraction,
              confirmed_quantity: 0,
              quantity: product.fraction,
              is_sold_by_weight: product.is_sold_by_weight,
              line_note: '',
              confirmed_quantity_traceback: [],
              cost: product.cost, // Will be recalculated
              cost_total: product.cost, // Will be recalculated
              gross_total_money: product.price, // Will be recalculated
              is_confirmed: false,
              item_id: product.id,
              line_discounts: [],
              line_taxes: [],
              total_line_added_taxes: 0,
              total_line_included_taxes: 0,
              added_product_taxes: [],
              product_discounts: discounts || [],
              missing: {
                employee_id: '',
                employee_name: '',
                item_id: product.id,
                quantity: 0,
                reason: ReasonMissing.NA,
              },
              missing_quantity_traceback: [],
              sku: product.store_sku,
              total_discount: 0,
              total_money: product.price, // Will be recalculated
            };

            // Attach applicable taxes to the product
            let productTaxes: Tax[] = [];

            if (product.taxes && product.taxes.length > 0) {
              if (typeof product.taxes[0] === 'string') {
                productTaxes = taxes
                  ? taxes.filter((tax) =>
                      (product.taxes as string[])
                        .map((x) => x.toLowerCase())
                        .includes(tax.id.toLowerCase()),
                    )
                  : [];
              } else {
                productTaxes = taxes
                  ? taxes.filter((tax) =>
                      (product.taxes as Tax[])
                        .map((t) => t.id.toLowerCase())
                        .includes(tax.id.toLowerCase()),
                    )
                  : [];
              }
            }

            newItem.added_product_taxes = productTaxes;

            return { ...cart, cart_items: [...cart.cart_items, newItem] };
          }
        }),
      );
    },
    [currentCartId, setCartData, setCurrentCartId, discounts, taxes, store],
  );

  const updateItemQuantity = useCallback(
    (itemId: string, quantity: number): void => {
      if (!currentCartId) return;

      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;

          const cartItemItems = cart.cart_items.map((item) =>
            item.id === itemId
              ? {
                  ...item,
                  line_quantity: quantity,
                  quantity: quantity * item.fraction,
                }
              : item,
          );
          return { ...cart, cart_items: cartItemItems };
        }),
      );

      deleteEmptyCarts();
    },
    [currentCartId, setCartData],
  );

  const updateItemNote = useCallback(
    (itemId: string, note: string): void => {
      if (!currentCartId) return;

      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;

          const cartItemItems = cart.cart_items.map((item) =>
            item.id === itemId ? { ...item, line_note: note } : item,
          );
          return { ...cart, cart_items: cartItemItems };
        }),
      );
    },
    [currentCartId, setCartData],
  );

  const deleteItem = useCallback(
    (itemId: string): void => {
      if (!currentCartId) return;

      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;

          const cartItemItems = cart.cart_items.filter(
            (item) => item.id !== itemId,
          );
          return { ...cart, cart_items: cartItemItems };
        }),
      );

      deleteEmptyCarts();
    },
    [currentCartId, setCartData],
  );

  const updateCustomer = useCallback(
    (customer: Customer): void => {
      if (!currentCartId) return;

      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;
          return {
            ...cart,
            customer_id: customer.id,
            customer,
            customer_name: customer.name ?? '',
            customer_number: customer.phone_number ?? '',
          };
        }),
      );
    },
    [currentCartId, setCartData],
  );

  const deleteCart = useCallback(
    async (cartId: string): Promise<void> => {
      if (!cartId) return;

      // Remove the cart from the Recoil state
      setCartData((prevCartData) =>
        prevCartData.filter((cart) => cart.cart_id !== cartId),
      );

      // Remove the cart from IndexedDB if it has a customer_id
      const cartToDelete = cartData.find((cart) => cart.cart_id === cartId);
      if (cartToDelete && cartToDelete.customer_id) {
        try {
          const compositeKey: [string, string, string] = [
            cartToDelete.customer_id,
            cartToDelete.store_id,
            cartToDelete.cart_id,
          ];
          await db.customerCarts.delete(compositeKey);
        } catch (error) {
          console.error('Failed to delete cart from IndexedDB:', error);
        }
      }

      // If the deleted cart was the current cart, reset currentCartId
      if (cartId === currentCartId) {
        setCurrentCartId(null);
      }
    },
    [cartData, currentCartId, setCartData, setCurrentCartId],
  );

  const deleteEmptyCarts = useCallback(() => {
    setCartData((prevCartData) => {
      const updatedCartData = prevCartData.filter((cart) => {
        if (!cart) return false;
        if (!cart.cart_items) return false;
        const isEmpty = cart.cart_items.length === 0;
        if (isEmpty && cart.cart_id === currentCartId) {
          setCurrentCartId(null);
        }
        return !isEmpty;
      });
      return updatedCartData;
    });
  }, [currentCartId, setCartData, setCurrentCartId]);

  const updatePayment = useCallback(
    (payment: ReceiptPayment): void => {
      if (!store || !currentCartId || !currentCart) return;

      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== currentCartId) return cart;

          const existingPaymentIndex = cart.payments.findIndex(
            (p) => p.payment_type_id === payment.payment_type_id,
          );

          const totalPayments = cart.payments.reduce(
            (acc, p) =>
              acc +
              (p.payment_type_id === payment.payment_type_id
                ? 0
                : p.money_amount),
            0,
          );

          const maxAllowedPayment = cart.gross_total_money - totalPayments;

          let adjustedAmount = payment.money_amount;

          // If payment exceeds the max allowed, cap it and calculate change
          if (payment.money_amount > maxAllowedPayment) {
            adjustedAmount = maxAllowedPayment;
            setChange(payment.money_amount - maxAllowedPayment);
          } else {
            setChange(0); // Reset change if within allowed payment
          }

          if (adjustedAmount <= 0) {
            const updatedPayments = cart.payments.filter(
              (p) => p.payment_type_id !== payment.payment_type_id,
            );
            return { ...cart, payments: updatedPayments };
          }

          if (existingPaymentIndex !== -1) {
            const updatedPayments = [...cart.payments];
            updatedPayments[existingPaymentIndex] = {
              ...cart.payments[existingPaymentIndex],
              ...payment,
              money_amount: adjustedAmount,
              updated_at: new Date().toISOString(),
            };
            return { ...cart, payments: updatedPayments };
          } else {
            const newPayment = {
              ...payment,
              money_amount: adjustedAmount,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
            };
            return { ...cart, payments: [...cart.payments, newPayment] };
          }
        }),
      );
    },
    [currentCartId, currentCart, setCartData, store],
  );

  const addDiscountToCartItem = useCallback(
    (cartId: string, itemId: string, discount: Discount): void => {
      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== cartId) return cart;

          const cartItemItems = cart.cart_items.map((item) =>
            item.id === itemId
              ? {
                  ...item,
                  product_discounts: [...item.product_discounts, discount],
                }
              : item,
          );

          return { ...cart, cart_items: cartItemItems };
        }),
      );
    },
    [setCartData],
  );

  const updateAdditionalDiscounts = useCallback(
    (cartId: string, additional_discounts: Discount[]): void => {
      setCartData((prevCartData) =>
        prevCartData.map((cart) =>
          cart.cart_id === cartId ? { ...cart, additional_discounts } : cart,
        ),
      );
    },
    [setCartData],
  );

  const applyCustomerPoints = useCallback(
    (cartId: string, points: number): void => {
      setCartData((prevCartData) =>
        prevCartData.map((cart) =>
          cart.cart_id === cartId
            ? {
                ...cart,
                customer_points: points,
              }
            : cart,
        ),
      );
    },
    [setCartData],
  );

  const removeAllCustomerPoints = useCallback(
    (cartId: string): void => {
      setCartData((prevCartData) =>
        prevCartData.map((cart) =>
          cart.cart_id === cartId
            ? {
                ...cart,
                customer_points: 0,
              }
            : cart,
        ),
      );
    },
    [setCartData],
  );

  const removeDiscountFromCartItem = useCallback(
    (cartId: string, itemId: string, discountId: string): void => {
      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== cartId) return cart;

          const cartItemItems = cart.cart_items.map((item) =>
            item.id === itemId
              ? {
                  ...item,
                  product_discounts: item.product_discounts.filter(
                    (discount) => discount.id !== discountId,
                  ),
                }
              : item,
          );

          return { ...cart, cart_items: cartItemItems };
        }),
      );
    },
    [setCartData],
  );

  const addTaxToCartItem = useCallback(
    (cartId: string, itemId: string, tax: Tax): void => {
      setCartData((prevCartData) =>
        prevCartData.map((cart) => {
          if (cart.cart_id !== cartId) return cart;

          const cartItemItems = cart.cart_items.map((item) =>
            item.id === itemId
              ? {
                  ...item,
                  added_product_taxes: [...item.added_product_taxes, tax],
                }
              : item,
          );

          return { ...cart, cart_items: cartItemItems };
        }),
      );
    },
    [setCartData],
  );

  const resetPayments = useCallback(
    (cartId: string): void => {
      setCartData((prevCartData) =>
        prevCartData.map((cart) =>
          cart.cart_id === cartId ? { ...cart, payments: [] } : cart,
        ),
      );
      setChange(0);
    },
    [setCartData],
  );

  return {
    cartData: currentCart ? currentCart.cart_items : [],
    cart: currentCart,
    change,
    numberOfItemsInCart,
    setChange,
    addItem,
    updateItemQuantity,
    updateItemNote,
    deleteItem,
    updateCustomer,
    deleteCart,
    updatePayment,
    addDiscountToCartItem,
    addTaxToCartItem,
    removeDiscountFromCartItem,
    updateAdditionalDiscounts,
    applyCustomerPoints,
    removeAllCustomerPoints,
    resetPayments,
  };
};
