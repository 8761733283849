import { MerchantUserAttributes, colors } from '@bofrak-backend/shared';
import {
  Badge,
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Container,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FlexProps,
  HStack,
  Heading,
  Icon,
  Link,
  LinkProps,
  Modal,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spacer,
  Stack,
  Text,
  TextProps,
  VStack,
  useColorModeValue as mode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiOutlineCloudSync } from 'react-icons/ai';
import { FaPeopleGroup } from 'react-icons/fa6';
import { FiArrowUpRight, FiHome, FiX } from 'react-icons/fi';
import { TbReceipt, TbReceiptOff } from 'react-icons/tb';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import usePageVisibility from '../../hooks/use-page-visibility';
import {
  isScreenLockedAtom,
  offlineReceiptsAtom,
  onlineReceiptsAtom,
  posDeviceAtom,
  shouldSignOutAtom,
  storeAtom,
  userAtom,
  workerActionsAtom,
} from '../../recoil/atoms';
import { envVars } from '../../utils/constants';
import { WebWorkerActions } from '../../utils/types';
import SyncCustomersWorker from '../../web-workers/sync-customers.ts?worker';
import SyncDiscounts from '../../web-workers/sync-discounts.ts?worker';
import SyncOfflineReceipts from '../../web-workers/sync-offline-receipts.ts?worker';
import SyncOnlineReceipts from '../../web-workers/sync-online-receipts.ts?worker';
import SyncPaymentTypes from '../../web-workers/sync-payment-types.ts?worker';
import SyncStoreProductsWorker from '../../web-workers/sync-store-products.ts?worker';
import SyncTaxes from '../../web-workers/sync-taxes.ts?worker';
import { ColumnHeader, ColumnIconButton } from '../column';
import CreateCustomerOverlay from '../customers/create-customer-overlay';
import CustomerOverlay from '../customers/customer-overlay';
import SyncData, { SyncEntityConfig } from '../notifications/sync-data';
import ReceiptOverlay from '../receipts/receipts-overlay';

interface NavbarProps extends FlexProps {
  onClose?: () => void;
}

export const Navbar = (props: NavbarProps) => {
  const [shouldSignOut, setShouldSignOut] = useRecoilState(shouldSignOutAtom);
  const [lockScreen, setLockScreen] = useRecoilState(isScreenLockedAtom);

  const onlineReceipts = useRecoilValue(onlineReceiptsAtom);
  const offlineReceipts = useRecoilValue(offlineReceiptsAtom);
  const user = useRecoilValue(userAtom);
  const posDevice = useRecoilValue(posDeviceAtom);
  const store = useRecoilValue(storeAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const hasVisibilityChanged = usePageVisibility();
  const [showPin, setShowPin] = useState(false); // State to toggle PIN visibility
  const setActions = useSetRecoilState(workerActionsAtom);
  const {
    isOpen: isViewCustomersOverlayOpen,
    onOpen: onOpenViewCustomersOverlay,
    onClose: onCloseViewCustomersOverlay,
  } = useDisclosure();

  const {
    isOpen: isCreateCustomerOverlayOpen,
    onOpen: onOpenCreateCustomerOverlay,
    onClose: onCloseCreateCustomerOverlay,
  } = useDisclosure();

  const {
    isOpen: isOpenReceipts,
    onOpen: onOpenReceipts,
    onClose: onCloseReceipts,
  } = useDisclosure();
  const {
    isOpen: isOpenOfflineReceipts,
    onOpen: onOpenOfflineReceipts,
    onClose: onCloseOfflineReceipts,
  } = useDisclosure();
  const [pin, setPin] = useState('');
  const toast = useToast();

  const syncData: SyncEntityConfig[] = [
    {
      name: 'Products',
      action: WebWorkerActions.SYNC_PRODUCTS,
      backendUrl: `${envVars.BASE_URL}/products/${store?.merchant_id}/store-products/${store?.id}`,
      worker: SyncStoreProductsWorker,
    },
    {
      name: 'Customers',
      action: WebWorkerActions.SYNC_CUSTOMERS,
      backendUrl: `${envVars.BASE_URL}/customers/${store?.merchant_id}`,
      worker: SyncCustomersWorker,
    },
    {
      name: 'Online Receipts',
      action: WebWorkerActions.SYNC_ONLINE_RECEIPTS,
      backendUrl: `${envVars.BASE_URL}/receipts/${store?.merchant_id}?by_store=true&store_id=${store?.id}`,
      worker: SyncOnlineReceipts,
    },
    {
      name: 'Offline Receipts',
      action: WebWorkerActions.SYNC_OFFLINE_RECEIPTS,
      backendUrl: `${envVars.BASE_URL}/receipts/${store?.merchant_id}`,
      worker: SyncOfflineReceipts,
      should_sync_offline: true,
    },
    {
      name: 'Discounts',
      action: WebWorkerActions.SYNC_DISCOUNTS,
      backendUrl: `${envVars.BASE_URL}/discounts/${store?.id}`,
      worker: SyncDiscounts,
    },
    {
      name: 'Payment Types',
      action: WebWorkerActions.SYNC_PAYMENT_TYPES,
      backendUrl: `${envVars.BASE_URL}/payment-types/${store?.merchant_id}`,
      worker: SyncPaymentTypes,
    },
    {
      name: 'Taxes',
      action: WebWorkerActions.SYNC_TAXES,
      backendUrl: `${envVars.BASE_URL}/taxes/${store?.merchant_id}`,
      worker: SyncTaxes,
    },
  ];

  useEffect(() => {
    if (lockScreen) {
      onOpen();
    }
  }, [lockScreen]);

  useEffect(() => {
    if (
      window.location.hostname !== 'localhost' &&
      window.location.hostname !== '127.0.0.1'
    )
      onOpen();
  }, [hasVisibilityChanged]);

  return (
    <>
      <Flex
        as="nav"
        height="full"
        direction="column"
        justify="space-between"
        {...props}>
        <Stack spacing="3">
          <ColumnHeader>
            <VStack
              // All children should be justified to the left
              align="flex-start"
              mt={'10px'}>
              <ColumnIconButton
                onClick={props.onClose}
                aria-label="Close navigation"
                icon={<FiX />}
                display={{ base: 'inline-flex', lg: 'none' }}
              />
              <Text fontWeight="bold" fontSize="sm" lineHeight="1.25rem">
                {user?.name}
              </Text>
              <Text
                textTransform="uppercase"
                fontSize="sm"
                lineHeight="1.25rem">
                {posDevice?.name} - {posDevice?.prefix}
              </Text>
              <Text
                textTransform="uppercase"
                fontSize="sm"
                lineHeight="1.25rem">
                {store?.name}
              </Text>
            </VStack>
          </ColumnHeader>

          <Stack px="3" spacing="6">
            <Stack spacing="1">
              <NavLink icon={FiHome}>Home</NavLink>
              <NavLink onClick={onOpenReceipts} icon={TbReceipt}>
                <HStack width="full">
                  <Text> Receipts </Text>
                  <Spacer />
                  <Badge
                    ml="1"
                    fontSize="0.8em"
                    color={'white'}
                    bg={colors.green}>
                    {onlineReceipts.length}
                  </Badge>
                </HStack>
              </NavLink>
              {offlineReceipts.length && (
                <NavLink onClick={onOpenOfflineReceipts} icon={TbReceiptOff}>
                  <HStack width="full">
                    <Text> Offline Receipts </Text>
                    <Spacer />
                    <Badge
                      ml="1"
                      fontSize="0.8em"
                      color={'white'}
                      bg={colors.red}>
                      {offlineReceipts.length}
                    </Badge>
                  </HStack>
                </NavLink>
              )}
              <NavLink
                onClick={onOpenViewCustomersOverlay}
                icon={FaPeopleGroup}>
                <HStack width="full">
                  <Text> Customers </Text>
                  <Spacer />
                </HStack>
              </NavLink>
            </Stack>
            <Stack spacing="3">
              <NavHeading>Actions</NavHeading>
              <Stack spacing="1">
                <NavLink
                  onClick={() => {
                    setActions([
                      WebWorkerActions.SYNC_PRODUCTS,
                      WebWorkerActions.SYNC_CUSTOMERS,
                      WebWorkerActions.SYNC_ONLINE_RECEIPTS,
                      WebWorkerActions.SYNC_OFFLINE_RECEIPTS,
                      WebWorkerActions.SYNC_DISCOUNTS,
                      WebWorkerActions.SYNC_PAYMENT_TYPES,
                      WebWorkerActions.SYNC_TAXES,
                    ]);
                  }}
                  icon={AiOutlineCloudSync}>
                  Sync
                </NavLink>
              </Stack>
            </Stack>
            <Stack spacing="3">
              <NavHeading>Notifications & Events</NavHeading>
              <Stack spacing="1" direction={'column'}>
                {syncData.map((entity) => (
                  <SyncData key={entity.name} entity={entity} />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Box borderTopWidth="1px">
          <NavButton
            onClick={() => {
              setLockScreen(true);
              onOpen();
            }}>
            Lock Screen
          </NavButton>
          <NavButton
            borderTopWidth="1px"
            isLoading={shouldSignOut}
            onClick={() => {
              setShouldSignOut(true);
            }}>
            Sign Out
          </NavButton>
        </Box>
      </Flex>
      <>
        <Drawer
          onClose={onCloseReceipts}
          isOpen={isOpenReceipts}
          placement="left"
          size={'full'}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Box height={'full'} width={'full'}>
              <ReceiptOverlay />
            </Box>
          </DrawerContent>
        </Drawer>
        <Drawer
          onClose={onCloseOfflineReceipts}
          isOpen={isOpenOfflineReceipts}
          placement="left"
          size={'full'}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Box height={'full'} width={'full'}>
              <ReceiptOverlay onlyOfflineReceipts={true} />
            </Box>
          </DrawerContent>
        </Drawer>
        <Modal
          onClose={onClose}
          closeOnOverlayClick={false}
          isOpen={isOpen}
          isCentered>
          <ModalOverlay />
          <ModalContent bg="transparent">
            <Container py={{ base: '16', md: '24' }}>
              <Box
                bg="white"
                py={{ base: '10', md: '16' }}
                px="6"
                borderRadius="lg"
                boxShadow="sm">
                <Stack spacing={{ base: '8', md: '10' }} align="center">
                  <Stack spacing={{ base: '4', md: '5' }} textAlign="center">
                    <Heading size={{ base: 'sm', md: 'md' }}>
                      Enter Your PIN
                    </Heading>
                  </Stack>
                  <Stack
                    direction="column"
                    width="full"
                    maxW={{ md: 'lg' }}
                    align={'center'}
                    spacing="4">
                    <HStack>
                      <PinInput
                        type="alphanumeric"
                        mask={showPin ? false : true}
                        onChange={(value) => setPin(value)}
                        otp>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                    <Checkbox
                      isChecked={showPin}
                      onChange={(e) => setShowPin(e.target.checked)}
                      colorScheme="blue">
                      Show PIN
                    </Checkbox>
                    <HStack>
                      <Button
                        width={100}
                        variant={'solid'}
                        borderRadius={'full'}
                        bg={colors.red}
                        onClick={() => {
                          setShouldSignOut(true);
                        }}>
                        Sign Out
                      </Button>
                      <Spacer />
                      <Button
                        variant={'solid'}
                        borderRadius={'full'}
                        width={100}
                        bg={colors.blue}
                        onClick={() => {
                          setLockScreen(false);
                          if (!user?.auth_user) {
                            toast({
                              title: 'User is not authenticated',
                              description: 'Please Login to unlock the screen',
                              status: 'error',
                              duration: 9000,
                              isClosable: true,
                            });
                          }

                          if (
                            pin ===
                            (user?.auth_user as MerchantUserAttributes)?.pin
                          ) {
                            onClose();
                          } else {
                            toast({
                              title: 'Incorrect PIN',
                              description: 'Please try again',
                              status: 'error',
                              duration: 9000,
                              isClosable: true,
                            });
                          }
                        }}>
                        Unlock
                      </Button>
                    </HStack>
                  </Stack>
                </Stack>
              </Box>
            </Container>
          </ModalContent>
        </Modal>
        <CustomerOverlay
          createCustomer={() => {
            onCloseViewCustomersOverlay();
            onOpenCreateCustomerOverlay();
          }}
          isSelectable={false}
          isOpen={isViewCustomersOverlayOpen}
          onClose={onCloseViewCustomersOverlay}
          placement="top"
        />
        <CreateCustomerOverlay
          isOpen={isCreateCustomerOverlayOpen}
          onClose={onCloseCreateCustomerOverlay}
          placement="bottom"
        />
      </>
    </>
  );
};

const NavButton = (props: ButtonProps) => (
  <Button
    width="full"
    borderRadius="0"
    variant="tertiary"
    size="lg"
    fontSize="sm"
    _hover={{ bg: mode('gray.100', 'gray.700') }}
    _active={{ bg: mode('gray.200', 'gray.600') }}
    _focus={{ boxShadow: 'none' }}
    _focusVisible={{ boxShadow: 'outline' }}
    {...props}
  />
);

interface NavLinkProps extends LinkProps {
  icon: React.ElementType;
}

export const NavLink = (props: NavLinkProps) => {
  const { icon, ...linkProps } = props;
  return (
    <Link
      px="2"
      py="1.5"
      borderRadius="md"
      _hover={{ bg: mode('gray.100', 'gray.700') }}
      _activeLink={{
        bg: 'gray.700',
        color: 'white',
      }}
      {...linkProps}>
      <HStack justify="space-between">
        <HStack as="a" spacing="3">
          <Icon as={icon} />
          <Text as="span" fontSize="sm" lineHeight="1.25rem">
            {props.children}
          </Text>
        </HStack>
        {props.isExternal && (
          <Icon
            as={FiArrowUpRight}
            boxSize="4"
            color={mode('gray.600', 'gray.400')}
          />
        )}
      </HStack>
    </Link>
  );
};

export const NavHeading = (props: TextProps) => (
  <Text
    as="h4"
    fontSize="xs"
    fontWeight="semibold"
    px="2"
    lineHeight="1.25"
    color={mode('gray.600', 'gray.400')}
    {...props}
  />
);
