// db.ts
import {
  StoreProduct,
  Customer,
  PosDevice,
  Receipt,
  Tax,
  Discount,
  PaymentType,
  Threshold,
} from '@bofrak-backend/shared';
import Dexie, { Table } from 'dexie';
import { CustomerCart } from '../utils/types';

export class ShopAndSmilePOSAppDatabase extends Dexie {
  storeProducts!: Table<StoreProduct, [string, string]>;
  customers!: Table<Customer, [string, string]>;
  posDevices!: Table<PosDevice, [string, string, string]>;
  onlineReceipts!: Table<Receipt, [string, string]>;
  offlineReceipts!: Table<Receipt, [string, string]>;
  taxes!: Table<Tax, [string, string]>;
  storeDiscounts!: Table<Discount, [string, string]>;
  paymentTypes!: Table<PaymentType, [string, string]>;
  thresholds!: Table<Threshold, [string, string]>;
  customerCarts!: Table<CustomerCart, [string, string, string]>;

  constructor() {
    super('ShopAndSmilePOSAppDatabase');
    this.version(1).stores({
      storeProducts: `
        &[store_id+id],
        merchant_id,
        is_parent,
        store_sku,
        is_available_for_sale,
        is_manually_controlled,
        is_sold_online,
        is_available_in_store,
        [merchant_id+store_id],
        [store_id+is_parent],
        [store_id+store_sku],
        [store_id+is_available_for_sale],
        [store_id+is_manually_controlled],
        [store_id+is_sold_online],
        [store_id+is_available_in_store]
      `,
      customers: `
        &[merchant_id+id],
        [merchant_id+phone_number],
        [merchant_id+name]
      `,
      posDevices: `
        &[merchant_id+store_id+current_user_id]
        `,
      offlineReceipts: `
        &[store_id+receipt_number],
        store_id,
        receipt_number,
        created_at,
        pos_device_id,
        receipt_type,
        refund_for
      `,
      onlineReceipts: `
        &[store_id+receipt_number],
        store_id,
        receipt_number,
        created_at,
        pos_device_id,
        receipt_type,
        refund_for
      `,
      taxes: `
        &[merchant_id+id],
        id,
        merchant_id
      `,
      storeDiscounts: `
        &[store_id+id],
        id,
        store_id
      `,
      paymentTypes: `
        &[merchant_id+id],
        id,
        merchant_id
      `,
      thresholds: `
        &[merchant_id+store_id],
        store_id,
        merchant_id
      `,
      customerCarts: `
        &[customer_id+store_id+cart_id],
        customer_id,
        store_id,
        cart_id
      `,
    });
  }
}

export const db = new ShopAndSmilePOSAppDatabase();
