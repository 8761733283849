export class TaxCalculator {
  // Method to calculate 'added to the price' tax
  static calculateAddedTax(itemPrice: number, taxRate: number): number {
    const taxValue = itemPrice * (taxRate / 100);
    return taxValue;
  }

  // Method to calculate 'included in the price' tax
  static calculateIncludedTax(
    itemPrice: number,
    taxRate: number,
  ): { preTaxPrice: number; taxValue: number } {
    const preTaxPrice = itemPrice / (1 + taxRate / 100);
    const taxValue = itemPrice - preTaxPrice;
    return {
      preTaxPrice: parseFloat(preTaxPrice.toFixed(2)),
      taxValue: parseFloat(taxValue.toFixed(2)),
    };
  }

  // Method to calculate multiple 'added to the price' taxes
  static calculateMultipleAddedTaxes(
    itemPrice: number,
    taxRates: number[],
  ): number {
    let totalTax = 0;
    taxRates.forEach((rate) => {
      totalTax += this.calculateAddedTax(itemPrice, rate);
    });
    return parseFloat(totalTax.toFixed(2));
  }

  // Method to calculate multiple 'included in the price' taxes
  static calculateMultipleIncludedTaxes(
    itemPrice: number,
    taxRates: number[],
  ): number {
    const totalTaxRate = taxRates.reduce((sum, rate) => sum + rate / 100, 0);
    const includedTaxValue = (itemPrice * totalTaxRate) / (1 + totalTaxRate);
    return parseFloat(includedTaxValue.toFixed(2));
  }

  // Method to calculate the 'added to the price' tax when price includes taxes
  static calculateAddedTaxWithIncluded(
    itemPrice: number,
    addedTaxRate: number,
    includedTaxValue: number,
  ): number {
    const reducedPrice = itemPrice - includedTaxValue;
    const addedTax = reducedPrice * (addedTaxRate / 100);
    return parseFloat(addedTax.toFixed(2));
  }
}
