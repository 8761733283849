import { selector } from 'recoil';
import {
  selectedReceiptAtom,
  onlineReceiptsAtom,
  offlineReceiptsAtom,
} from './atoms';
import { Receipt } from '@bofrak-backend/shared';
import { uniqBy } from 'lodash';

export const associatedRefundsSelector = selector<Receipt[]>({
  key: 'associatedRefundsSelector',
  get: ({ get }) => {
    // Get the selected receipt
    const selectedReceipt = get(selectedReceiptAtom);

    // If no receipt is selected or it has no associated refunds, return null
    if (!selectedReceipt || !selectedReceipt.associated_refunds.length) {
      return [];
    }

    // Get online and offline receipts
    const onlineReceipts = get(onlineReceiptsAtom);
    const offlineReceipts = get(offlineReceiptsAtom);

    // Combine online and offline receipts
    const allReceipts = uniqBy(
      [...onlineReceipts, ...offlineReceipts],
      'receipt_number',
    );

    // Filter receipts that match the associated refunds of the selected receipt
    const associatedRefunds = allReceipts.filter((receipt) =>
      selectedReceipt.associated_refunds.includes(receipt.receipt_number),
    );

    return associatedRefunds;
  },
  set: ({ get, set }, newAssociatedRefunds) => {
    // Get the selected receipt
    const selectedReceipt = get(selectedReceiptAtom);

    // If there's no selected receipt, do nothing
    if (!selectedReceipt) return;

    // Update associated_refunds in the selected receipt
    if (Array.isArray(newAssociatedRefunds)) {
      const updatedReceipt = {
        ...selectedReceipt,
        associated_refunds: newAssociatedRefunds.map(
          (receipt: Receipt) => receipt.receipt_number,
        ),
      };

      set(selectedReceiptAtom, updatedReceipt);
    }
  },
});
