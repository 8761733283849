import { Timestamps } from './shared.types';

//help.loyverse.com/help/how-discounts-are-calculated

export enum DiscountType {
  FIXED_PERCENT = 'FIXED_PERCENT',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  VARIABLE_PERCENT = 'VARIABLE_PERCENT',
  VARIABLE_AMOUNT = 'VARIABLE_AMOUNT',
  DISCOUNT_BY_POINTS = 'DISCOUNT_BY_POINTS',
}

export interface Discount extends Timestamps {
  id: string; // UUID. Optional for creation, required for update.
  type: DiscountType; // Discount type.
  name: string; // Discount name. Length between 1 and 40 characters.
  discount_amount?: number; // Discount value in money, required only for FIXED_AMOUNT.
  discount_percent?: number; // Discount value in percentage, required only for FIXED_PERCENT.
  store_id: string;
  restricted_access?: boolean; // If true, password verification is required to apply.
}

export interface DiscountPage {
  discounts: Discount[];
  count: number;
  cursor?: string;
}

export interface CreateDiscount {
  type: DiscountType;
  name: string;
  discount_amount?: number;
  discount_percent?: number;
  store_id: string;
  restricted_access?: boolean;
}

export interface UpdateDiscount {
  id: string;
  type?: DiscountType;
  name?: string;
  discount_amount?: number;
  discount_percent?: number;
  store_id: string;
  restricted_access?: boolean;
}

export interface DiscountDBItem extends Discount {
  PK: string;
  SK: string;
  GSI1PK: string;
  GSI1SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
}

export interface GetDiscountById {
  id: string;
}

export interface GetDiscounts {
  store_id: string;
  limit: number;
  cursor?: string;
}

export interface DiscountPage {
  discounts: Discount[];
  cursor?: string;
  count: number;
}

export enum CalculateDiscountCommands {
  PERCENTAGE = 'PERCENTAGE', // Apply a percentage discount to the item or receipt
  FIXED = 'FIXED', // Apply a fixed amount discount to the item or receipt
  MULTIPLE_DISCOUNTS = 'MULTIPLE_DISCOUNTS', // Apply multiple discounts (percentage + fixed) to the item or receipt
  ITEM_LEVEL_DISCOUNT = 'ITEM_LEVEL_DISCOUNT', // Apply item-level discounts where each item can have its own discounts
  RECEIPT_LEVEL_DISCOUNT = 'RECEIPT_LEVEL_DISCOUNT', // Apply receipt-level discount across all items in the receipt
  TAX_AFTER_DISCOUNT = 'TAX_AFTER_DISCOUNT', // Apply tax after discounts have been applied
  TAX_BEFORE_DISCOUNT = 'TAX_BEFORE_DISCOUNT', // Apply tax before discounts are applied
}

export interface CalculateDiscount {
  item_price: number;
  discount_ids: string[];
  added_discount_id: string;
  included_discount_ids: string[];
  command: CalculateDiscountCommands;
}

export interface CalculateDiscountResultItem {
  // The list of items, used for item-level discounts
  price: number; // Original price of the item
  finalPrice: number; // Final price of the item after applying discounts
  discount_value?: number; // Total discount applied to this item
  discount_ids: string[]; // List of discount IDs applied to the item
}

export interface CalculateDiscountResult {
  discount_command: CalculateDiscountCommands; // The command that was used for this discount calculation
  item_price?: number; // The original item price (for single-item discounts)
  final_price?: number; // The final price after discounts (and possibly taxes) have been applied
  discount_value?: number; // The total amount discounted
  total_discount?: number; // The total discount applied across all items or to the receipt
  total_tax?: number; // The total tax applied (used in tax-before/after scenarios)
  items?: CalculateDiscountResultItem[]; // The list of items with their final prices and discounts
}
