import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  StackProps,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useCart } from '../../hooks/use-cart';
import { cartItemsAtom, currentCartIdAtom } from '../../recoil/atoms';
import { colors, formatCurrency } from '@bofrak-backend/shared';
import { SearchInput } from '../search-input';
import CustomerOverlay from '../customers/customer-overlay';

import CartStack from './cart-stack';
import { CustomerCartsSummary } from './cart-summary';
import CartTotals from './cart-totals';
import PaymentForm from './payment-form';
import CreateCustomerOverlay from '../customers/create-customer-overlay';

interface SidebarProps extends StackProps {
  openCart: () => void;
  isCartOpen: boolean;
  closeCart: () => void;
}

export const Sidebar = ({
  openCart,
  isCartOpen,
  closeCart,
  ...rest
}: SidebarProps) => {
  const {
    cartData,
    cart,
    updateItemQuantity,
    deleteItem,
    updateItemNote,
    deleteCart,
  } = useCart();
  const carts = useRecoilValue(cartItemsAtom);
  const toast = useToast();
  const [currentCartId, setCurrentIdCart] = useRecoilState(currentCartIdAtom);
  const {
    isOpen: isCustomerOverlayOpen,
    onOpen: onOpenCustomerOverlay,
    onClose: onCloseCustomerOverlay,
  } = useDisclosure();
  const {
    isOpen: isCreateCustomerOverlayOpen,
    onOpen: onOpenCreateCustomerOverlay,
    onClose: onCloseCreateCustomerOverlay,
  } = useDisclosure();
  const [customerQuery, setCustomerQuery] = useState('');
  const bgGradient = useColorModeValue(
    'linear(to-l, gray.50 50%, white 50%)',
    'linear(to-l, gray.700 50%, gray.800 50%)',
  );

  const leftBox = useColorModeValue('white', 'gray.800');
  const rightBoxBgBase = useColorModeValue('white', 'gray.800');

  useEffect(() => {
    if (!cart) {
      closeCart();
    }
  }, [cart]);

  return isCartOpen ? (
    <Stack height={'full'} {...rest}>
      <Box height={'full'} bgGradient={bgGradient}>
        <Flex
          maxW="8xl"
          mx="auto"
          height="full"
          direction={{ base: 'column', md: 'row' }}>
          <Box
            flex="1"
            px={{ base: '4', md: '8' }}
            py={{ base: '6', md: '8' }}
            bg={leftBox}>
            <PaymentForm />
          </Box>
          <Box
            flex="1"
            bg={{ base: rightBoxBgBase, md: 'inherit' }}
            px={{ base: '4', md: '8' }}
            py={{ base: '6', md: '8' }}>
            <Stack
              padding="0"
              height={{
                base: '50vh',
                md: '55%',
              }}
              spacing="8"
              overflowY="auto">
              <CartStack
                isCheckout={true}
                cartData={cartData}
                deleteItem={deleteItem}
                updateItemNote={updateItemNote}
                updateItemQuantity={updateItemQuantity}
              />
            </Stack>
            <CartTotals />
          </Box>
        </Flex>
      </Box>
    </Stack>
  ) : (
    <Stack
      height={'94%'}
      spacing={{ base: '1px', lg: '1' }}
      px={{ lg: '3' }}
      py="3"
      {...rest}>
      <Stack padding="0" height="full" spacing="8" overflowY="auto">
        <Tabs isFitted>
          <TabList>
            <Tab>Current Items</Tab>
            <Tab>Draft Items</Tab>
          </TabList>
          <TabIndicator />
          <TabPanels>
            <TabPanel>
              <CartStack
                isCheckout={true}
                cartData={cartData}
                deleteItem={deleteItem}
                updateItemNote={updateItemNote}
                updateItemQuantity={updateItemQuantity}
              />
            </TabPanel>
            <TabPanel>
              <SearchInput
                setQuery={setCustomerQuery}
                placeholder="Search customers, phone, store..."
              />
              <CustomerCartsSummary
                query={customerQuery}
                carts={carts}
                deleteCart={deleteCart}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
      <Stack
        borderTopWidth="1px"
        px={{ base: '6', md: '10' }}
        py="2"
        spacing="5">
        <Stack>
          <HStack fontSize="xl" fontWeight="semibold">
            <Text flex="1">Subtotal:</Text>
            <Text>
              {formatCurrency(
                cartData
                  .map((x) => x.total_money)
                  .reduce((prev, curr) => prev + curr, 0),
              )}
            </Text>
          </HStack>
        </Stack>
        <HStack width={'full'} justify={'space-between'}>
          <Button
            onClick={() => {
              if (!currentCartId || !cart) return;
              if (cart && cart.customer_id) {
                onCloseCustomerOverlay();
                setCurrentIdCart(null);
                toast({
                  title: 'Cart Saved',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                });
              } else {
                onOpenCustomerOverlay();
              }
            }}
            isDisabled={!currentCartId || !(cart && cart.customer_id) || !cart}
            bg={colors.primary}
            size="lg"
            borderRadius={'full'}
            fontSize="md">
            Save Cart
          </Button>
          <Button
            onClick={() => {
              if (!currentCartId || !cart) return;
              if (cart && cart.customer_id) {
                openCart();
              } else {
                onOpenCustomerOverlay();
              }
            }}
            isDisabled={!currentCartId || !cart}
            bg={colors.green}
            size="lg"
            borderRadius={'full'}
            fontSize="md">
            Continue
          </Button>
        </HStack>
      </Stack>
      <CustomerOverlay
        isOpen={isCustomerOverlayOpen}
        onClose={onCloseCustomerOverlay}
        placement="top"
        createCustomer={() => {
          onCloseCustomerOverlay();
          onOpenCreateCustomerOverlay();
        }}
        isSelectable={true}
      />
      <CreateCustomerOverlay
        isOpen={isCreateCustomerOverlayOpen}
        onClose={onCloseCreateCustomerOverlay}
        placement="bottom"
      />
    </Stack>
  );
};
