import React, { ReactNode } from 'react';
import {
  Box,
  Center,
  Container,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';

interface ErrorBoundaryProps {
  children?: ReactNode; // Make children optional
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    console.log(
      '🚀 ~ file: error-boundary.tsx:30 ~ getDerivedStateFromError ~ _:',
      _,
    );

    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log error details
    console.error('Error captured in ErrorBoundary:', error);
    console.error('Error info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Center>
          <Container maxW="3xl" mt="10%">
            <Box
              bg="bg.surface"
              boxShadow="sm"
              borderRadius="lg"
              p={{ base: '4', md: '6' }}>
              <VStack spacing="5">
                <Heading textStyle="lg" fontWeight="medium">
                  Oops!
                </Heading>
                <Text textStyle="lg" color="fg">
                  Something went wrong. Please try again later.
                </Text>
              </VStack>
            </Box>
          </Container>
        </Center>
      );
    }

    // Render children only if they exist
    return this.props.children || null;
  }
}

export default ErrorBoundary;
