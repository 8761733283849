import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import { SearchCustomer } from './search-customer';
import { useCart } from '../../hooks/use-cart';

interface CustomerOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  createCustomer: () => void;
  placement: 'top' | 'right' | 'bottom' | 'left';
  isSelectable: boolean;
}

const CustomerOverlay = (props: CustomerOverlayProps) => {
  const {
    isOpen,
    onClose,
    placement = 'top',
    createCustomer,
    isSelectable,
  } = props;
  const { updateCustomer } = useCart();

  return (
    <Drawer size={'xl'} placement={placement} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <SearchCustomer
            isSelectable={isSelectable}
            createCustomer={createCustomer}
            onSelectCustomer={(customer) => {
              updateCustomer(customer);
              onClose();
            }}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CustomerOverlay;
