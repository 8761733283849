import {
  Box,
  Flex,
  Stack,
  StackProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { selectedReceiptAtom } from '../../recoil/atoms';
import ReceiptDetails from './receipt-details';
import { SearchReceipts } from './search-receipts';

interface ReceiptOverlayProps extends StackProps {
  onlyOfflineReceipts?: boolean;
}

const ReceiptOverlay = ({
  onlyOfflineReceipts,
  ...rest
}: ReceiptOverlayProps) => {
  const selectedReceipt = useRecoilValue(selectedReceiptAtom);

  const bgGradient = useColorModeValue(
    'linear(to-l, gray.50 50%, white 50%)',
    'linear(to-l, gray.700 50%, gray.800 50%)',
  );

  return (
    <Stack height={'full'} {...rest}>
      <Box
        height={'full'}
        bg={{
          base: 'gray.50', // Fully gray.50 in base (mobile) layout
          md: selectedReceipt ? bgGradient : 'white', // Gradient only on md and larger if a receipt is selected
        }}>
        <Flex
          maxW="8xl"
          mx="auto"
          height="full"
          overflowY={{ base: 'auto', md: 'hidden' }}
          direction={{ base: 'column', md: 'row' }}>
          <Box flex="1">
            <SearchReceipts onlyOfflineReceipts={onlyOfflineReceipts} />
          </Box>

          {selectedReceipt && (
            <Box height={'full'} flex="1" padding={2}>
              <ReceiptDetails />
            </Box>
          )}
        </Flex>
      </Box>
    </Stack>
  );
};

export default ReceiptOverlay;
