// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react';
import { theme } from '@chakra-ui/pro-theme';
import { colors } from '@bofrak-backend/shared';

const proTheme = extendTheme(theme);
const extendedConfig = {
  colors: { ...proTheme.colors, brand: colors.primary },
};
export const myTheme = extendTheme(extendedConfig, proTheme);
