import { formatCurrency } from '@bofrak-backend/shared';
import {
  HStack,
  StackProps,
  Text,
  TextProps,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface PriceTagProps {
  currency: string;
  price: number;
  salePrice?: number;
  rootProps?: StackProps;
  priceProps?: TextProps;
  salePriceProps?: TextProps;
}

export type FormatPriceOptions = { locale?: string; currency?: string };

export const PriceTag = (props: PriceTagProps) => {
  const { price, salePrice, rootProps, priceProps, salePriceProps } = props;

  // Determine if the salePrice is a valid number and not greater than the price
  const isValidSalePrice =
    typeof salePrice === 'number' &&
    Number.isFinite(salePrice) &&
    salePrice < price;

  return (
    <HStack spacing="1" {...rootProps}>
      <Price isOnSale={isValidSalePrice} textProps={priceProps}>
        {formatCurrency(price)}
      </Price>
      {isValidSalePrice && (
        <SalePrice {...salePriceProps}>{formatCurrency(salePrice)}</SalePrice>
      )}
    </HStack>
  );
};

interface PriceProps {
  children?: ReactNode;
  isOnSale?: boolean;
  textProps?: TextProps;
}

const Price = (props: PriceProps) => {
  const { isOnSale, children, textProps } = props;
  const defaultColor = mode('gray.700', 'gray.400');
  const onSaleColor = mode('gray.400', 'gray.700');
  const color = isOnSale ? onSaleColor : defaultColor;
  return (
    <Text
      as="span"
      fontWeight="semibold"
      fontSize="sm"
      color={color}
      textDecoration={isOnSale ? 'line-through' : 'none'}
      {...textProps}>
      {children}
    </Text>
  );
};

const SalePrice = (props: TextProps) => (
  <Text
    as="span"
    fontWeight="semibold"
    fontSize="sm"
    color={mode('gray.900', 'gray.100')}
    {...props}
  />
);
