import { Heading, HeadingProps } from '@chakra-ui/react';
import { colors } from '@bofrak-backend/shared';

const AppName = (props: HeadingProps) => {
  return (
    <Heading
      textTransform="uppercase"
      fontSize="lg"
      letterSpacing="wider"
      whiteSpace="nowrap"
      color={colors.primary}
      {...props}>
      Shop And Smile
    </Heading>
  );
};

export default AppName;
