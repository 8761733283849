import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource-variable/open-sans';
import '@fontsource-variable/spline-sans';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './routes/root';
import { myTheme } from './theme';
import { paths } from './utils/constants';
import { HomePage } from './routes/home';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorBoundary from './components/error-boundary';

const router = createBrowserRouter([
  {
    path: paths.home,
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: [{ index: true, element: <HomePage /> }],
      },
    ],
  },
]);

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ChakraProvider theme={myTheme}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>,
);
