import { colors } from '@bofrak-backend/shared';
import {
  Button,
  Flex,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { CartItemInterface } from '../../utils/types';
import { PriceTag } from './price-tag';

type CartItemProps = {
  isCheckout?: boolean;
  onClickDelete?: () => void;
  onChangeNote?: (note: string) => void;
  onChangeCartQuantity?: (quantity: number) => void;
  is_sold_by_weight: boolean;
} & CartItemInterface;

export const CartItem = (props: CartItemProps) => {
  const {
    item_name,
    is_sold_by_weight,
    line_note,
    currency,
    price,
    line_quantity,
    onClickDelete,
    onChangeNote,
    onChangeCartQuantity,
    isCheckout,
  } = props;

  return (
    <Stack
      boxShadow={'xl'}
      m={3}
      borderRadius={isCheckout ? 'lg' : 'xl'}
      padding={isCheckout ? 2 : 1}
      direction="row"
      spacing="1">
      <Stack width="full" spacing="3">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing="3"
          alignItems="flex-start">
          <Stack width="full">
            <Text fontSize={'sm'} fontWeight={'semibold'}>
              {item_name}
            </Text>
          </Stack>
          <PriceTag price={price} salePrice={price} currency={currency} />
        </Stack>
        <Input
          width={'full'}
          size={'sm'}
          borderWidth={0}
          boxShadow={'inner'}
          borderRadius={'2xl'}
          placeholder="Add a note (optional)"
          value={line_note}
          onChange={(e) => onChangeNote?.(e.target.value)}
          color={useColorModeValue('gray.700', 'gray.300')}
          focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
        />
        <Flex width="full" justifyContent="space-between" alignItems="center">
          <NumberInput
            size="md"
            m={1}
            value={line_quantity}
            aria-label="Select quantity"
            onChange={(valueString) => {
              const valueNumber = is_sold_by_weight
                ? parseFloat(valueString) || 0
                : parseInt(valueString) || 0;
              if (valueNumber > 0) {
                onChangeCartQuantity?.(valueNumber);
              }
            }}
            maxW={24}
            step={is_sold_by_weight ? 0.1 : 1}
            defaultValue={line_quantity}
            min={is_sold_by_weight ? 0.1 : 1}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

          <Button
            m={1}
            size={'xs'}
            borderRadius={'full'}
            variant={'outline'}
            color={colors.red}
            borderColor={colors.red}
            onClick={onClickDelete}>
            Remove
          </Button>
        </Flex>
      </Stack>
    </Stack>
  );
};
