import { Customer } from './customers.types';
import { Discount } from './discounts.types';
import { PaymentMethodType } from './payment-types.types';
import {
  EmployeeSummary,
  ReceiptType,
  SortOrder,
  Timestamps,
} from './shared.types';
import { Tax } from './taxes.types';

export enum ReasonMissing {
  NA = 'NA',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  CANCELLED = 'CANCELLED',
}

export enum ReceiptStatus {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
  AUTOCONFIRMED = 'AUTOCONFIRMED',
  REFUND = 'REFUND',
  MISSING = 'MISSING',
}

export interface MissingItem {
  quantity: number;
  reason: ReasonMissing;
  employee_id: string;
  employee_name: string;
  item_id: string;
}

export interface CartItem extends Timestamps {
  item_id: string;
  item_name: string;
  quantity: number;
  missing: MissingItem;
  is_confirmed: boolean;
}

export interface ConfirmedQuantityUpdate {
  quantity: number;
  updator: EmployeeSummary;
  item_id: string;
}

export interface ExtendedLineItem extends LineItem {
  missing: MissingItem;
  is_confirmed: boolean;
}

export interface ReceiptHistory {
  receipt_number: string;
  history_item: ReceiptHistoryItem[];
}

export enum ReceiptHistoryItemType {
  SALE = 'SALE',
  DEBT_PAYMENT = 'DEBT PAYMENT',
  REFUND = 'REFUND',
  UPDATE_CUSTOMER = 'CUSTOMER UPDATE',
  COMMENT = 'COMMENT',
}

export interface HistoryPaymentType {
  payment_type_id: string;
  payment_type_name: string;
  payment_type: PaymentMethodType;
  amount: number;
}

export interface SaleReceiptHistoryItem {
  history_item_type: ReceiptHistoryItemType.SALE;
  event: string;
  value: number;
  remaining_debt: number;
  payments: HistoryPaymentType[];
  comment?: string;
  date: string;
  receipt_number: string;
}

export interface DebtPaymentHistoryItem {
  history_item_type: ReceiptHistoryItemType.DEBT_PAYMENT;
  event: string;
  paid_amount: number;
  remaining_debt: number;
  payment_id: string;
  comment?: string;
  date: string;
  received_by: EmployeeSummary;
}

export interface RefundReceiptHistoryItem {
  history_item_type: ReceiptHistoryItemType.REFUND;
  event: string;
  refund_receipt_number: string;
  refunded_payments: HistoryPaymentType[];
  amount_refunded: number;
  comment?: string;
  remaining_debt: number;
  date: string;
  refunded_by: EmployeeSummary;
}

export interface ReceiptHistoryUpdateCustomerItem {
  history_item_type: ReceiptHistoryItemType.UPDATE_CUSTOMER;
  event: string;
  previous_customer_id: string;
  new_customer_id: string;
  date: string;
  updated_by: EmployeeSummary;
}

export interface ReceiptHistoryCommentItem {
  history_item_type: ReceiptHistoryItemType.COMMENT;
  event: string;
  comment: string;
  date: string;
  commented_by: EmployeeSummary;
}

//////// SHOP AND SMILE RECEIPT ///////////

export interface LineItem {
  id: string;
  item_id: string;
  item_name: string;
  sku: string;
  quantity: number;
  line_quantity: number;
  fraction: number;
  price: number;
  gross_total_money: number;
  total_money: number;
  cost: number;
  cost_total: number;
  line_note?: string;
  line_taxes: ReceiptTax[];
  total_discount: number;
  line_discounts: ReceiptDiscount[];
  confirmed_quantity: number;
  confirmed_quantity_traceback: ConfirmedQuantityTraceback[];
  missing: MissingItem;
  missing_quantity_traceback: MissingItemTraceback[];
  is_confirmed: boolean;
  is_sold_by_weight: boolean;
}

export interface ReceiptDiscount extends Discount {
  money_amount: number;
}

export interface ReceiptTax extends Tax {
  money_amount: number;
}

export interface Receipt extends Timestamps {
  receipt_number: string;
  note?: string;
  receipt_type: ReceiptType;
  refund_for?: string;
  order?: string;
  created_at: string;
  updated_at: string;
  source: string;
  cancelled_at?: any;
  total_money: number;
  total_tax: number;
  points_earned: number;
  points_deducted: number;
  points_balance: number;
  customer_id: string;
  total_discount: number;
  employee_id: string;
  store_id: string;
  pos_device_id: string;
  dining_option?: string;
  total_discounts: ReceiptDiscount[];
  total_taxes: ReceiptTax[];
  tip: number;
  surcharge: number;
  line_items: LineItem[];
  payments: ReceiptPayment[];
  associated_refunds: string[];
  total_receipt_items: number;
  confirmation_type: ConfirmationType;
  receipt_status: ReceiptStatus;
  comments: Comment[];
  client: Client;
  waiter?: EmployeeSummary;
  is_confirmable: boolean;
  confirmed_by?: EmployeeSummary;
  customer?: Customer;
  customer_name: string;
  employee_name: string;
  pos_device_name: string;
  customer_updated_traceback: CustomerUpdateTraceback[];
  has_debt: boolean;
  custom_receipt_number: string;
  failed_checks: ReceiptFailedChecks[];
  merchant_id: string;
  threshold: Threshold;
}

export interface ReceiptFailedChecks {
  note: string;
  check_type: any;
  created_at: string;
}

export interface ReceiptPayment extends Timestamps {
  payment_type_id: string;
  money_amount: number;
  note?: string;
}

export interface ReceiptDBItem extends Receipt {
  PK: string; // RECEIPT#<receipt_number>
  SK: string; // RECEIPT#<receipt_number>
  EntityIndexPK: string; // RECEIPT
  EntityIndexSK: string; // RECEIPT#DATE#<created_at>
  GSI1PK: string; // <receipt_status>
  GSI1SK: string; // RECEIPT#DATE#<date>
  GSI2PK: string; // <customer_id>
  GSI2SK: string; // RECEIPT#DATE#<date>
  GSI3PK: string; // STORE#<store_id>
  GSI3SK: string; // RECEIPT#DATE#<date>
  GSI4PK: string; // POS_DEVICE#<pos_device_id>
  GSI4SK: string; // RECEIPT#DATE#<date>
  GSI5PK: string; // POS_DEVICE#<id>
  GSI5SK: string; // RECEIPT#<custom_receipt_number>
  GSI6PK: string; // STORE#<store_id>#STATUS#<receipt_status>
  GSI6SK: string; // RECEIPT#DATE#<date>
  GSI7PK: string; // STORE#<store_id>#CONFIRMATION_TYPE#<confirmation_type>
  GSI7SK: string; // RECEIPT#DATE#<date>
}

export interface CreateReceiptHistoryItem {
  receiptHistory: ReceiptHistoryItem;
  id: string;
  receipt_number: string;
  created_at: string;
  merchant_id: string;
}

export interface UpdateReceiptItem extends Partial<ReceiptDBItem> {
  receipt_number: string;
}

export type ReceiptHistoryDbItem = ReceiptHistoryItem & {
  PK: string;
  SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
};

export type ReceiptHistoryItem =
  | SaleReceiptHistoryItem
  | DebtPaymentHistoryItem
  | RefundReceiptHistoryItem
  | ReceiptHistoryUpdateCustomerItem
  | ReceiptHistoryCommentItem;

export interface Client extends Timestamps {
  id: string;
  name: string;
  phone_number: string;
  email?: string;
  address?: string;
  business_name?: string;
}

export interface Comment extends Timestamps {
  comment: string;
  employee_id: string;
  employee_name: string;
}

export enum ConfirmationType {
  SOFT = 'SOFT',
  HARD = 'HARD',
}

export interface ConfirmedQuantityTraceback extends Timestamps {
  quantity: number;
  confirmed_by: string;
  note: string;
}

export interface CustomerUpdateTraceback extends Timestamps {
  customer: string;
  updator: string;
  note: string;
}

export interface MissingItemTraceback extends Timestamps {
  quantity: number;
  reported_by: string;
  note: string;
}

export interface GetReceipts {
  cursor?: string;
  limit?: number;
  start_date?: string;
  end_date?: string;
  receipt_status?: ReceiptStatus;
  customer_id?: string;
  store_id?: string;
  confirmation_type?: ConfirmationType;
  pos_device_id?: string;
  by_status?: boolean;
  by_date_range?: boolean;
  by_date_range_and_status?: boolean;
  by_customer?: boolean;
  by_customer_and_date_range?: boolean;
  by_date_range_and_status_and_missing?: boolean;
  by_store?: boolean;
  by_store_and_date_range?: boolean;
  by_store_and_status?: boolean;
  by_store_and_date_range_and_status?: boolean;
  by_store_and_confirmation_type?: boolean;
  by_pos_device?: boolean;
  sort_order?: SortOrder;
}

export interface ReceiptsPage {
  receipts: Receipt[];
  cursor?: string;
  count: number;
}

export enum UpdateReceiptCommand {
  CONFIRM_SOFT_RECEIPT = 'CONFIRM_SOFT_RECEIPT',
  CONFIRM_HARD_RECEIPT = 'CONFIRM_HARD_RECEIPT',
  ADD_COMMENT = 'ADD_COMMENT',
  UPDATE_CONFIRMED_QUANTITY = 'UPDATE_CONFIRMED_QUANTITY',
  ADD_MISSING_QUANTITY = 'ADD_MISSING_QUANTITY',
  DELETE_MISSING_QUANTITY = 'DELETE_MISSING_QUANTITY',
  SET_RECEIPT_TO_MISSING = 'SET_RECEIPT_TO_MISSING',
  ADD_WAITER = 'ADD_WAITER',
  ADD_CUSTOMER = 'ADD_CUSTOMER',
}

export interface UpdateReceipt {
  receipt_number: string;
  merchant_id: string;
  command: UpdateReceiptCommand;
  waiter?: EmployeeSummary;
  comments?: Comment[];
  receipt_status?: {
    receipt_status: ReceiptStatus;
    updator: EmployeeSummary;
  };
  customer?: {
    customer_id: string;
    updator_id: string;
  };
}

export interface UpdateLineItem {
  receipt_number: string;
  confirmed_quantity?: ConfirmedQuantityUpdate[];
  missing?: MissingItem[];
  command: UpdateReceiptCommand;
  merchant_id: string;
}

export interface ConfirmReceipt {
  receipt_number: string;
  updator: EmployeeSummary;
  confirmation_type: ConfirmationType;
  command: UpdateReceiptCommand;
  merchant_id: string;
}

export interface Threshold extends Timestamps {
  min_quantity: number;
  min_total_money: number;
  updated_by: EmployeeSummary[];
  store_id: string;
  merchant_id: string;
}

export interface ThresholdItem extends Timestamps, Threshold {
  PK: string;
  SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
}

export interface CreateThreshold {
  min_quantity: number;
  min_total_money: number;
  updated_by: EmployeeSummary;
  store_id: string;
  merchant_id: string;
}

export interface UpdateThreshold {
  min_quantity?: number;
  min_total_money?: number;
  updated_by: EmployeeSummary;
  store_id: string;
  merchant_id: string;
}
