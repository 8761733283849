export const paths = {
  home: '/',
};

export const envVars = {
  CLOUDFRONT_PHOTO_URL: import.meta.env.VITE_CLOUDFRONT_PHOTO_URL,
  S3_BUCKET_FOLDER_NAME: import.meta.env.VITE_S3_BUCKET_FOLDER_NAME,
  BASE_URL: import.meta.env.VITE_BASE_URL,
  STAGE: import.meta.env.VITE_STAGE,
  REGION: import.meta.env.VITE_REGION,
  COGNITO_USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID,
  COGNITO_IDENTITY_POOL_ID: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
  USER_POOL_CLIENT_ID: import.meta.env.VITE_USER_POOL_CLIENT_ID,
};

export const colors = {
  primary: '#007AFF',
  green: '#34c759',
  blue: '#007AFF',
  red: '#ff3a30',
  cyan: '#64d3ff',
  gray: '#c7c7cc',
};
