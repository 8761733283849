import { Timestamps } from './shared.types';

//help.loyverse.com/help/how-taxes-are-calculated

export enum TaxType {
  INCLUDED = 'INCLUDED',
  ADDED = 'ADDED',
}

/**
 * Tax interface
 * @interface
 * @name Tax
 * @property {string} id - The tax id
 * @property {TaxType} type - The tax type
 * @property {string} name - The tax name
 * @property {number} rate - The tax rate. For example, a value of "5.255" corresponds to a percentage of 5.255%
 * @property {string} merchant_id - The merchant id
 * @property {string} created_at - The created at timestamp
 * @property {string} updated_at - The updated at timestamp
 * @property {string} deleted_at - The deleted at timestamp
 * @example
 * {
 *  id: 'tax_id',
 * type: TaxType.INCLUDED,
 * name: 'tax_name',
 * rate: 0.1,
 * merchant_id: 'merchant_id',
 *
 */

export interface Tax extends Timestamps {
  id: string;
  type: TaxType;
  name: string;
  rate: number;
  merchant_id: string;
}

/**
 * Tax page interface
 * @interface
 * @name TaxPage
 * @property {Tax[]} taxes - The tax list
 * @property {number} count - The tax count
 * @property {string} cursor - The cursor
 * @example
 * {
 *  taxes: [],
 * count: 0,
 * cursor: 'cursor',
 * }
 */

export interface TaxPage {
  taxes: Tax[];
  count: number;
  cursor?: string;
}

/**
 * Create tax interface
 * @interface
 * @name CreateTax
 * @property {string} name - The tax name
 * @property {TaxType} type - The tax type
 * @property {number} rate - The tax rate
 * @property {string} merchant_id - The merchant id
 * @example
 * {
 *  name: 'tax_name',
 * type: TaxType.INCLUDED,
 * rate: 0.1,
 * merchant_id: 'merchant_id',
 * }
 */

export interface CreateTax {
  name: string;
  type: TaxType;
  rate: number;
  merchant_id: string;
}

/**
 * Update tax interface
 * @interface
 * @name UpdateTax
 * @property {string} id - The tax id
 * @property {string} name - The tax name
 * @property {TaxType} type - The tax type
 * @property {number} rate - The tax rate
 * @property {string} merchant_id - The merchant id
 * @example
 * {
 *  id: 'tax_id',
 * name: 'tax_name',
 * type: TaxType.INCLUDED,
 * rate: 0.1,
 * merchant_id: 'merchant_id',
 * }
 */

export interface UpdateTax {
  id: string;
  name?: string;
  type?: TaxType;
  rate?: number;
  merchant_id: string;
}

/**
 * Tax DB item interface
 * @interface
 * @name TaxDBItem
 * @property {string} PK - The partition key
 * @property {string} SK - The sort key
 * @property {string} GSI1PK - The global secondary index 1 partition key
 * @property {string} GSI1SK - The global secondary index 1 sort key
 * @property {string} EntityIndexPK - The tax entity index partition key
 * @property {string} EntityIndexSK - The tax entity index sort key
 * @property {string} id - The tax id
 * @property {TaxType} type - The tax type
 * @property {string} name - The tax name
 * @property {number} rate - The tax rate
 * @property {string} merchant_id - The merchant id
 * @property {string} created_at - The created at timestamp
 * @property {string} updated_at - The updated at timestamp
 * @property {string} deleted_at - The deleted at timestamp
 * @example
 * {
 * PK: 'TAX#{tax_id}',
 * SK: 'TAX#{tax_id}',
 * GSI1PK: 'STORE#{merchant_id}',
 * GSI1SK: 'TAX#{tax_id}',
 * EntityIndexPK: 'TAX',
 * EntityIndexSK: 'STORE#{merchant_id}#DATE#{created_at}',
 * id: 'tax_id',
 * type: TaxType.INCLUDED,
 * name: 'tax_name',
 * rate: 0.1,
 * merchant_id: 'merchant_id',
 * created_at: 'timestamp',
 * updated_at: 'timestamp',
 * deleted_at: 'timestamp',
 * }
 */

export interface TaxDBItem extends Tax {
  PK: string;
  SK: string;
  GSI1PK: string;
  GSI1SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
}

/**
 * Get tax by id interface
 * @interface
 * @name GetTaxById
 * @property {string} id - The tax id
 * @example
 * {
 * id: 'tax_id',
 * }
 */

export interface GetTaxById {
  id: string;
}

/**
 * Get taxes by merchant id interface
 * @interface
 * @name GetTaxesByStoreId
 * @property {string} merchant_id - The merchant id
 * @property {number} limit - The limit
 * @property {string} cursor - The cursor
 * @example
 * {
 * merchant_id: 'merchant_id',
 * limit: 10,
 * cursor: 'cursor',
 * }
 */

export interface GetTaxes {
  merchant_id: string;
  limit: number;
  cursor?: string;
}

/**
 * Calculate tax commands
 * @enum
 * @name CalculateTaxCommands
 *
 */

export enum CalculateTaxCommands {
  ADDED_TO_THE_PRICE = 'ADDED_TO_THE_PRICE',
  INCLUDED_IN_THE_PRICE = 'INCLUDED_IN_THE_PRICE',
  MULTIPLE_ADDED_TO_THE_PRICE = 'MULTIPLE_ADDED_TO_THE_PRICE',
  MULTIPLE_INCLUDED_IN_THE_PRICE = 'MULTIPLE_INCLUDED_IN_THE_PRICE',
  ADDED_TO_THE_PRICE_WITH_INCLUDED = 'ADDED_TO_THE_PRICE_WITH_INCLUDED',
}

/**
 * Calculate tax interface
 * @interface
 * @name CalculateTax
 * @property {number} amount - The amount
 * @property {Tax[]} taxes - The tax list
 * @property {CalculateTaxCommands} command - The calculate tax command
 * @example
 * {
 * amount: 100,
 * taxes: [],
 * command: CalculateTaxCommands.ADDED,
 * }
 */

export interface CalculateTax {
  item_price: number;
  tax_ids: string[];
  added_tax_id: string;
  included_tax_ids: string[];
  command: CalculateTaxCommands;
}

export interface CalculateTaxResult {
  command: CalculateTaxCommands;
  item_price: number;
  tax_value: number | null; // Tax value for single added/included tax
  pre_tax_price: number | null; // Pre-tax price for included tax calculations
  total_tax: number | null; // Total tax value for multiple taxes (added/included)
  added_tax: number | null; // Added tax value for "added-with-included"
}
