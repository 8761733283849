import {
  Box,
  CircularProgress,
  SimpleGrid,
  VStack,
  Text,
} from '@chakra-ui/react';
import Fuse from 'fuse.js';
import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ProductCard } from '../components/products/product-card';
import { productsAtom, searchQueryAtom } from '../recoil/atoms';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../api/local';
import { useCart } from '../hooks/use-cart';

export const HomePage = () => {
  const searchQuery = useRecoilValue(searchQueryAtom);
  const setStoreProducts = useSetRecoilState(productsAtom);
  const { addItem, cartData } = useCart();

  // Use useLiveQuery to fetch all products from the storeProducts table
  const products = useLiveQuery(() => {
    return db.storeProducts.toArray();
  }, []);

  // Configure Fuse.js options and create a memoized Fuse instance
  const fuse = useMemo(() => {
    // Provide a default empty array if products is undefined
    const data = products
      ? products.filter((p) => p.is_parent && p.is_sold_online)
      : [];

    return new Fuse(data, {
      keys: ['name', 'description'], // Specify searchable fields
      threshold: 0.2, // Adjust for sensitivity
      ignoreLocation: true,
    });
  }, [products]);

  // Filter products based on search query
  const filteredProducts = useMemo(() => {
    if (products) {
      return searchQuery
        ? fuse.search(searchQuery).map((result) => result.item)
        : products.filter((p) => p.is_parent && p.is_sold_online);
    } else {
      return [];
    }
  }, [searchQuery, products, fuse]);

  useEffect(() => {
    if (products) {
      setStoreProducts(products);
    }
  }, [products]);

  // Handle the loading state while products are being fetched
  if (!products) {
    return (
      <Box
        maxW="7xl"
        mx="auto"
        px={{ base: '2', md: '4', lg: '6' }}
        py={{ base: '3', md: '4', lg: '6' }}>
        <VStack>
          <CircularProgress isIndeterminate color="blue.500" />
          <Text>Loading products...</Text>
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{ base: '2', md: '4', lg: '6' }}
      py={{ base: '3', md: '4', lg: '6' }}>
      <SimpleGrid
        columns={{ base: 2, sm: 2, md: 3, lg: 3 }}
        gap={{ base: '4', lg: '6' }}>
        {filteredProducts.map((product) => {
          // Find the product in the cartData array

          const productItemIds = [
            ...new Set([
              product.id,
              ...product.product_fractions.map(
                (fraction) => fraction.fraction_id,
              ),
            ]),
          ];

          const cart_quantity = cartData.reduce((acc, item) => {
            if (productItemIds.includes(item.item_id)) {
              return acc + item.line_quantity;
            }
            return acc;
          }, 0);

          return (
            <ProductCard
              key={product.id}
              product={product}
              addItem={addItem}
              cart_quantity={cart_quantity}
            />
          );
        })}
      </SimpleGrid>
    </Box>
  );
};
