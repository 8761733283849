import { colors } from '@bofrak-backend/shared';
import {
  Badge,
  Icon,
  IconButton,
  IconButtonProps,
  LightMode,
} from '@chakra-ui/react';
import { IoMdCloseCircle } from 'react-icons/io';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

interface IsAvailableProps {
  is_available_for_sale: boolean;
  cart_quantity?: number;
}

export const IsAvailable = ({
  is_available_for_sale,
  cart_quantity = 0,
  ...rest
}: IconButtonProps & IsAvailableProps) => (
  <LightMode>
    {cart_quantity <= 0 ? (
      <IconButton
        isRound
        bg="transparent"
        color={is_available_for_sale ? 'green' : 'red'}
        size="sm"
        _hover={{ transform: 'scale(1.1)' }}
        sx={{ ':hover > svg': { transform: 'scale(1.1)' } }}
        transition="all 0.15s ease"
        icon={
          <Icon
            as={
              is_available_for_sale ? IoCheckmarkCircleSharp : IoMdCloseCircle
            }
            transition="all 0.15s ease"
          />
        }
        boxShadow="none"
        {...rest}
      />
    ) : (
      <Badge
        bg={colors.blue}
        boxShadow="2xl"
        my={3}
        ml={3}
        p={'5px'}
        fontWeight={'black'}
        color={is_available_for_sale ? 'white' : 'red'}
        size="xs"
        _hover={{ transform: 'scale(1.1)' }}
        sx={{ ':hover > svg': { transform: 'scale(1.1)' } }}
        transition="all 0.15s ease"
        {...rest}>
        {/^-?\d+\.\d+$/.test(cart_quantity.toString())
          ? cart_quantity.toFixed(2)
          : cart_quantity}
      </Badge>
    )}
  </LightMode>
);
