/* eslint-disable @typescript-eslint/no-explicit-any */
export function unStringfy(str: any) {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.log('🚀 ~ file: functions.ts:7 ~ unStringfy ~ e:', e);

    return str;
  }
}
