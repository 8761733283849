import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import Fuse from 'fuse.js';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  offlineReceiptsAtom,
  onlineReceiptsAtom,
  searchQueryAtom,
  selectedReceiptAtom,
} from '../../recoil/atoms';
import { colors } from '@bofrak-backend/shared';
import { SearchInput } from '../search-input';
import { formatCurrency, Receipt, ReceiptType } from '@bofrak-backend/shared';
import { uniqBy } from 'lodash';

interface SearchReceiptsProps {
  onlyOfflineReceipts?: boolean;
}

export const SearchReceipts = ({
  onlyOfflineReceipts,
}: SearchReceiptsProps) => {
  const [query, setQuery] = useRecoilState(searchQueryAtom);

  // Manage the selected receipt state
  const [selectedReceipt, setSelectedReceipt] =
    useRecoilState(selectedReceiptAtom);

  const onlineReceipts = useRecoilValue(onlineReceiptsAtom);
  const offlineReceipts = useRecoilValue(offlineReceiptsAtom);

  const receipts = useMemo(() => {
    const merged = onlyOfflineReceipts
      ? [...(offlineReceipts || [])]
      : [...(onlineReceipts || []), ...(offlineReceipts || [])];

    return uniqBy(merged, 'receipt_number');
  }, [onlineReceipts, offlineReceipts, onlyOfflineReceipts]);

  // Configure Fuse.js options and create a memoized Fuse instance
  const fuse = useMemo(() => {
    const data = receipts || [];

    // Create a simplified array of receipts containing only searchable fields
    const simplifiedReceipts = data.map((receipt) => ({
      receipt_number: receipt.receipt_number,
      custom_receipt_number: receipt.custom_receipt_number,
      customer_name: receipt.customer_name,
      employee_name: receipt.employee_name,
      receipt_type: receipt.receipt_type,
      pos_device_name: receipt.pos_device_name,
      confirmation_type: receipt.confirmation_type,
      receipt_status: receipt.receipt_status,
    }));

    return new Fuse(simplifiedReceipts, {
      keys: [
        'receipt_number',
        'custom_receipt_number',
        'customer_name',
        'employee_name',
        'receipt_type',
        'pos_device_name',
        'confirmation_type',
        'receipt_status',
      ],
      threshold: 0.1,
      ignoreLocation: true,
      includeScore: true,
    });
  }, [receipts]);

  // Filter receipts based on search query
  const filteredReceipts = useMemo(() => {
    if (receipts) {
      // Map receipts by receipt_number for quick lookup
      const receiptMap = new Map();
      receipts.forEach((receipt) => {
        receiptMap.set(receipt.receipt_number, receipt);
      });

      const results = query
        ? fuse.search(query).map((result) => {
            // Retrieve the full receipt using receipt_number
            return receiptMap.get(result.item.receipt_number);
          })
        : receipts;

      // Sort the filtered receipts by created_at in descending order (newest first)
      return results.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      ) as Receipt[];
    } else {
      return [] as Receipt[];
    }
  }, [query, receipts, fuse]);

  // Handle card click
  const handleCardClick = (receipt: Receipt) => {
    if (
      selectedReceipt &&
      selectedReceipt.receipt_number === receipt.receipt_number
    ) {
      // If the clicked receipt is already selected, deselect it
      setSelectedReceipt(null);
    } else {
      // Otherwise, select the clicked receipt
      setSelectedReceipt(receipt);
    }
  };

  useEffect(() => {
    // When the component is mounted, set the query to an empty string
    setQuery('');

    return () => {
      // When the component is unmounted, reset the selected receipt
      setSelectedReceipt(null);
      setQuery('');
    };
  }, []);

  return (
    <Box>
      <Stack height={'full'} spacing="1" p="10px">
        <Stack
          mt={10}
          mb={5}
          mx={10}
          direction={{ base: 'column', md: 'row' }}
          justify="space-between">
          <SearchInput
            setQuery={setQuery}
            placeholder="Receipt, Customer, Employee, Type, POS, Status..."
          />
        </Stack>
        <Stack
          height={{
            base: 'calc(100vh - 200px)',
            md: 'full',
          }}
          maxH={{
            base: 'calc(100vh - 200px)',
            md: 'calc(100vh - 100px)',
          }}
          overflowY={'auto'}
          spacing="1">
          {filteredReceipts.map((receipt) => {
            const isSelected =
              selectedReceipt &&
              selectedReceipt.receipt_number === receipt.receipt_number;
            return (
              <Card
                cursor={'pointer'}
                borderColor={isSelected ? colors.primary : 'transparent'}
                borderWidth={isSelected ? '4px' : '1px'}
                my={1}
                mx={10}
                borderRadius={'3xl'}
                boxShadow={'xl'}
                key={receipt.receipt_number}
                onClick={() => handleCardClick(receipt)}>
                <CardHeader>
                  <Stack
                    width="full"
                    justify={{
                      base: 'center',
                      md: 'space-between',
                    }}
                    align="center"
                    direction={{
                      base: 'column',
                      md: 'row',
                    }}>
                    <Heading fontWeight={'black'} fontSize={'2xl'}>
                      {receipt.receipt_number}
                    </Heading>

                    <Text
                      fontSize={'xl'}
                      fontWeight={'bold'}
                      color={colors.primary}>
                      {formatCurrency(receipt.total_money)}
                    </Text>

                    <Badge
                      variant="outline"
                      colorScheme={
                        receipt.receipt_type === ReceiptType.SALE
                          ? 'green'
                          : 'purple'
                      }>
                      <Text
                        m="2"
                        fontWeight={'black'}
                        fontSize={'3xl'}
                        textAlign={'center'}>
                        {receipt.receipt_type}
                      </Text>
                    </Badge>
                  </Stack>
                </CardHeader>
                <CardBody>
                  <Stack spacing="1">
                    {receipt.refund_for && (
                      <HStack justify={'space-between'}>
                        <Text size="xs" fontWeight={'bold'}>
                          Refund For
                        </Text>

                        <Badge
                          size={'xs'}
                          variant="outline"
                          colorScheme="green">
                          {receipt.refund_for}
                        </Badge>
                      </HStack>
                    )}
                    {receipt.associated_refunds.length > 0 && (
                      <HStack
                        width={'full'}
                        maxW={'full'}
                        justify={'space-between'}>
                        <Text size="xs" fontWeight={'bold'}>
                          Refunds{' '}
                          <Text as="span" color={'gray'}>
                            &times; {receipt.associated_refunds.length}
                          </Text>
                        </Text>
                        <HStack
                          overflowX={'scroll'}
                          maxWidth={{
                            base: '200px',
                            md: '300px',
                          }}
                          spacing={'0.5'}>
                          {receipt.associated_refunds.map((refund) => (
                            <Badge
                              key={refund}
                              size="xs"
                              variant={'outline'}
                              colorScheme="purple">
                              {refund}
                            </Badge>
                          ))}
                        </HStack>
                      </HStack>
                    )}
                    <HStack justify={'space-between'}>
                      <Text size="xs" fontWeight={'bold'}>
                        Created
                      </Text>
                      <Text fontSize="sm">
                        {moment(receipt.created_at).format(
                          'ddd, DD MMM YYYY @ HH:MM',
                        )}
                      </Text>
                    </HStack>

                    {receipt.custom_receipt_number !==
                      receipt.receipt_number && (
                      <HStack justify={'space-between'}>
                        <Text size="xs" fontWeight={'bold'}>
                          Custom Receipt Number
                        </Text>
                        <Text fontSize="sm">
                          {receipt.custom_receipt_number}
                        </Text>
                      </HStack>
                    )}

                    <HStack justify={'space-between'}>
                      <Text size="xs" fontWeight={'bold'}>
                        Customer
                      </Text>
                      <Text fontSize="sm">{receipt.customer_name}</Text>
                    </HStack>
                    <HStack justify={'space-between'}>
                      <Text size="xs" fontWeight={'bold'}>
                        Employee
                      </Text>
                      <Text fontSize="sm">{receipt.employee_name}</Text>
                    </HStack>
                    <HStack justify={'space-between'}>
                      <Text size="xs" fontWeight={'bold'}>
                        POS
                      </Text>
                      <Text fontSize="sm">{receipt.pos_device_name}</Text>
                    </HStack>
                  </Stack>
                </CardBody>
              </Card>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};
