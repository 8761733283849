/* eslint-disable @typescript-eslint/no-explicit-any */
import { AtomEffect, DefaultValue } from 'recoil';
import { unStringfy } from '../utils/functions';
import { PosDevice } from '@bofrak-backend/shared';
import { db } from '../api/local';
import { CustomerCart } from '../utils/types';

export const localPersistEffect = ({ onSet, setSelf, node }: any) => {
  const storedItems = localStorage.getItem(node.key);
  if (storedItems != null) {
    setSelf(unStringfy(storedItems));
  }

  onSet((newItems: any) => {
    if (newItems instanceof DefaultValue) {
      localStorage.removeItem(node.key);
    } else {
      localStorage.setItem(node.key, JSON.stringify(newItems));
    }
  });
};

export const posDeviceAtomEffect =
  (): AtomEffect<PosDevice | null> =>
  ({ setSelf, onSet }) => {
    // Load initial state from IndexedDB
    const loadPersisted = async () => {
      try {
        // Get the composite key from localStorage
        const keyString = localStorage.getItem('selectedPosDeviceKey');
        if (keyString) {
          const key = JSON.parse(keyString) as [string, string, string];
          const savedValue = await db.posDevices.get(key);
          if (savedValue != null) {
            setSelf(savedValue);
          }
        }
      } catch (error) {
        console.error('Failed to load from IndexedDB:', error);
      }
    };

    loadPersisted();

    // Save changes to IndexedDB
    onSet(async (newValue, _, isReset) => {
      try {
        if (isReset) {
          // Delete the POS device from IndexedDB using the stored key
          const keyString = localStorage.getItem('selectedPosDeviceKey');
          if (keyString) {
            const key = JSON.parse(keyString) as [string, string, string];
            await db.posDevices.delete(key);
            localStorage.removeItem('selectedPosDeviceKey');
          }
        } else {
          const posDevice = newValue as PosDevice;
          if (
            posDevice.merchant_id &&
            posDevice.store_id &&
            posDevice.current_user_id
          ) {
            const key: [string, string, string] = [
              posDevice.merchant_id,
              posDevice.store_id,
              posDevice.current_user_id,
            ];

            // Store the POS device in IndexedDB with the composite key
            await db.posDevices.put(posDevice, key);

            // Save the composite key in localStorage for future retrieval
            localStorage.setItem('selectedPosDeviceKey', JSON.stringify(key));
          } else {
            console.error(
              'PosDevice is missing required fields: merchant_id, store_id, current_user_id',
            );
          }
        }
      } catch (error) {
        console.error('Failed to save to IndexedDB:', error);
      }
    });
  };

// Atom effect to persist CustomerCart to IndexedDB if they have a customer_id
export const customerCartAtomEffect: AtomEffect<CustomerCart[]> = ({
  setSelf,
  onSet,
}) => {
  // Load carts with customer_id from IndexedDB on initialization
  const loadPersisted = async () => {
    try {
      // Fetch all customer carts from IndexedDB
      const customerCarts = await db.customerCarts.toArray();
      // Filter carts that have customer_id
      const cartsWithCustomerId = customerCarts.filter(
        (cart) => cart.customer_id,
      );

      if (cartsWithCustomerId.length > 0) {
        // Merge with existing atom value
        setSelf((prevValue) => {
          if (prevValue instanceof DefaultValue) {
            // If prevValue is DefaultValue, set to cartsWithCustomerId
            return cartsWithCustomerId;
          } else {
            // Merge prevValue with cartsWithCustomerId
            const mergedCartsMap = new Map<string, CustomerCart>();

            // Add existing carts to map
            prevValue.forEach((cart) => {
              mergedCartsMap.set(cart.cart_id, cart);
            });

            // Overwrite or add carts from IndexedDB
            cartsWithCustomerId.forEach((cart) => {
              mergedCartsMap.set(cart.cart_id, cart);
            });

            // Convert map back to array
            return Array.from(mergedCartsMap.values());
          }
        });
      }
    } catch (error) {
      console.error('Failed to load customer carts from IndexedDB:', error);
    }
  };

  loadPersisted();

  // Save carts with customer_id to IndexedDB on state change
  onSet(async (newValue, oldValue, isReset) => {
    try {
      if (isReset) {
        // Atom was reset; clear all customer carts from IndexedDB
        await db.customerCarts.clear();
      } else {
        const newCarts = newValue as CustomerCart[];
        const oldCarts =
          oldValue instanceof DefaultValue ? [] : (oldValue as CustomerCart[]);

        // Create sets for new and old cart keys
        const newCartKeys = new Set<string>();
        newCarts.forEach((cart) => {
          if (cart.customer_id) {
            const key = `${cart.customer_id}-${cart.store_id}-${cart.cart_id}`;
            newCartKeys.add(key);
            // Save or update cart in IndexedDB
            const compositeKey: [string, string, string] = [
              cart.customer_id,
              cart.store_id,
              cart.cart_id,
            ];
            db.customerCarts.put(cart, compositeKey);
          }
        });

        const oldCartKeys = new Set<string>();
        oldCarts.forEach((cart) => {
          if (cart.customer_id) {
            const key = `${cart.customer_id}-${cart.store_id}-${cart.cart_id}`;
            oldCartKeys.add(key);
          }
        });

        // Identify carts to delete from IndexedDB
        const cartsToDelete = [...oldCartKeys].filter(
          (key) => !newCartKeys.has(key),
        );

        for (const key of cartsToDelete) {
          const [customer_id, store_id, cart_id] = key.split('-');
          const compositeKey: [string, string, string] = [
            customer_id,
            store_id,
            cart_id,
          ];
          await db.customerCarts.delete(compositeKey);
        }
      }
    } catch (error) {
      console.error('Failed to save customer carts to IndexedDB:', error);
    }
  });
};
