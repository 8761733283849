import {
  Box,
  BoxProps,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
} from '@chakra-ui/react';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';

import { StoreProduct, colors } from '@bofrak-backend/shared';
import { useState, useEffect } from 'react';

interface ProductCheckboxCardProps extends BoxProps {
  value: string;
  isInCart: boolean;
  cartId: string;
  quantity: number;
  lineQuantity: number;
  product: StoreProduct;
  updateItemQuantity: (cartId: string, quantity: number) => void;
  addItem: (product: StoreProduct) => void;
  deleteItem: (itemId: string) => void;
}

export const ProductCheckboxCard = (props: ProductCheckboxCardProps) => {
  const {
    cartId,
    quantity: unitQuantity,
    updateItemQuantity,
    lineQuantity,
    addItem,
    deleteItem,
    product,
    isInCart,
    children,
    ...rest
  } = props;

  // Store input values as strings
  const [weightValue, setWeightValue] = useState('');
  const [priceValue, setPriceValue] = useState('');

  useEffect(() => {
    if (product.is_sold_by_weight && isInCart) {
      const weight = lineQuantity.toString();
      const price = (lineQuantity * product.price).toString();
      setWeightValue(weight);
      setPriceValue(price);
    } else {
      setWeightValue('1');
      setPriceValue(product.price.toString());
    }
  }, [isInCart, product.is_sold_by_weight, lineQuantity, product.price]);

  const handleAddToCart = () => {
    if (isInCart) {
      deleteItem(cartId);
    } else {
      addItem(product);
    }
  };

  // Function to parse input values safely
  const parseInputValue = (value: string) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  return (
    <HStack
      opacity={product.is_available_for_sale ? 1 : 0.3}
      cursor={product.is_available_for_sale ? 'pointer' : 'not-allowed'}
      borderColor={isInCart ? colors.primary : 'transparent'}
      borderWidth={2}
      borderRadius={'2xl'}
      shadow={'xl'}
      p="4"
      width={'full'}>
      {isInCart &&
        (product.is_sold_by_weight ? (
          <InputGroup>
            <Input
              type="number"
              placeholder="1.5"
              value={weightValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                setWeightValue(inputValue);

                const newWeight = parseInputValue(inputValue);
                const newPrice = newWeight * product.price;
                setPriceValue(newPrice ? newPrice.toString() : '');

                updateItemQuantity(cartId, newWeight);
              }}
            />
            <InputRightElement
              pointerEvents="none"
              fontWeight={'bold'}
              color="gray.900"
              fontSize="sm">
              KG
            </InputRightElement>
          </InputGroup>
        ) : (
          <IconButton
            borderWidth={0}
            color={'white'}
            bg={colors.primary}
            borderRadius={'full'}
            padding={0}
            m={1}
            variant="outline"
            aria-label="Subtract"
            onClick={() => {
              if (unitQuantity > 1) {
                updateItemQuantity(cartId, lineQuantity - 1);
              } else {
                deleteItem(cartId);
              }
            }}
            icon={<IoIosRemove size={'45'} />}
          />
        ))}

      <Spacer />
      <Box
        as="label"
        width="full"
        cursor="pointer"
        onClick={handleAddToCart}
        sx={{
          '.focus-visible + [data-focus]': {
            boxShadow: 'outline',
            zIndex: 1,
          },
        }}>
        <Box {...rest}>{children}</Box>
      </Box>
      <Spacer />

      {isInCart &&
        (product.is_sold_by_weight ? (
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.900"
              fontWeight={'bold'}
              fontSize="sm">
              MT
            </InputLeftElement>
            <Input
              type="number"
              placeholder="1234"
              value={priceValue}
              onChange={(e) => {
                const inputValue = e.target.value;
                setPriceValue(inputValue);

                const newPrice = parseInputValue(inputValue);
                const newWeight = newPrice / product.price;

                setWeightValue(newWeight ? newWeight.toFixed(2) : '');

                updateItemQuantity(cartId, newWeight);
              }}
            />
          </InputGroup>
        ) : (
          <IconButton
            borderWidth={0}
            color={'white'}
            bg={colors.primary}
            borderRadius={'full'}
            padding={0}
            m={1}
            variant="outline"
            onClick={() => updateItemQuantity(cartId, lineQuantity + 1)}
            aria-label="Add"
            icon={<IoIosAdd size={'45'} />}
          />
        ))}
    </HStack>
  );
};
