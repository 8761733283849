import {
  Customer,
  Discount,
  LineItem,
  Receipt,
  ReceiptDiscount,
  ReceiptPayment,
  ReceiptTax,
  Tax,
} from '@bofrak-backend/shared';

export interface PosInventoryChange {
  store_id: string;
  inventory_change: number;
  product_id: string;
}

export interface CartItemInterface extends LineItem {
  original_price: number;
  original_cost: number;
  total_line_added_taxes: number;
  total_line_included_taxes: number;
  currency: string;
  image_url: string;
  fraction: number;
  added_product_taxes: Tax[];
  product_discounts: Discount[];
}

export enum WebWorkerActions {
  SYNC_PRODUCTS = 'sync-products',
  SYNC_TAXES = 'sync-taxes',
  SYNC_DISCOUNTS = 'sync-discounts',
  SYNC_CUSTOMERS = 'sync-customers',
  SYNC_OFFLINE_RECEIPTS = 'sync-offline-receipts',
  SYNC_ONLINE_RECEIPTS = 'sync-online-receipts',
  SYNC_PAYMENT_TYPES = 'sync-payment-types',
}

export interface LatestReceipt {
  receipt: Receipt | null;
  isOffline: boolean | null;
  numericReceiptNumber: number;
}

export interface CustomerCart {
  customer_id?: string;
  customer?: Customer;
  customer_name: string;
  customer_number: string;
  store_id: string;
  store_name: string;
  cart_id: string;
  cart_items: CartItemInterface[];
  payments: ReceiptPayment[];
  discounts: ReceiptDiscount[];
  total_taxes: ReceiptTax[];
  total_included_taxes: number;
  total_added_taxes: number;
  total_discounts: number;
  total_money: number;
  gross_total_money: number;
  additional_discounts: Discount[];
  customer_points: number;
}
