import { colors } from '@bofrak-backend/shared';
import { Center, Box, Badge, VisuallyHidden } from '@chakra-ui/react';
import { HiOutlineShoppingCart } from 'react-icons/hi';

interface ShoppingCartWithCountProps {
  count: number;
  openCart: () => void;
}

const ShoppingCartWithCount: React.FC<ShoppingCartWithCountProps> = ({
  count,
  openCart,
}) => {
  return (
    <Center
      w="8"
      h="8"
      as="button"
      type="button"
      onClick={openCart}
      position="relative">
      <VisuallyHidden>Toggle Menu</VisuallyHidden>
      <Box as={HiOutlineShoppingCart} fontSize="3xl" />
      {count > 0 && (
        <Badge
          position="absolute"
          bg={'transparent'}
          top="-1"
          right="3"
          transform="translate(50%, -50%)"
          color={colors.primary}
          fontWeight={'black'}
          fontSize="xs"
          minW="18px"
          textAlign="center">
          {/^-?\d+\.\d+$/.test(count.toString()) ? count.toFixed(2) : count}
        </Badge>
      )}
    </Center>
  );
};

export default ShoppingCartWithCount;
