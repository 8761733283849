import {
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';

interface SearchInputProps extends InputGroupProps {
  placeholder?: string;
  setQuery: (query: string) => void;
}

export const SearchInput = ({
  setQuery,
  placeholder,
  ...rest
}: SearchInputProps) => {
  // When the component is mounted, set the query to an empty string
  const [localQuery, setLocalQuery] = useState('');

  useEffect(() => {
    setQuery('');
    setLocalQuery('');
  }, []);

  return (
    <InputGroup {...rest}>
      <InputLeftElement>
        <Icon as={RiSearchLine} color="gray.500" fontSize="lg" />
      </InputLeftElement>
      <Input
        focusBorderColor="blue.500"
        width="full"
        borderRadius={'full'}
        fontSize="sm"
        variant="filled"
        type="text"
        placeholder={placeholder || 'What are you looking for?'}
        autoComplete="off"
        value={localQuery}
        onChange={({ target: { value } }) => {
          setLocalQuery(value);
          setQuery(value);
        }}
      />
    </InputGroup>
  );
};
