import {
  colors,
  ConfirmationType,
  formatCurrency,
  getReceiptId,
  LineItem,
  PaymentMethodType,
  PaymentType,
  Receipt,
  ReceiptPayment,
  ReceiptStatus,
  ReceiptType,
} from '@bofrak-backend/shared';
import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { flatten, groupBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ulid } from 'ulidx';
import { db } from '../../api/local';
import useOnReceiptCreated from '../../hooks/use-on-receipt-created';
import { useStep } from '../../hooks/use-step';
import {
  paymentTypesAtom,
  posDeviceAtom,
  selectedReceiptAtom,
  storeAtom,
  triggerRefetchAtom,
  userAtom,
  workerActionsAtom,
} from '../../recoil/atoms';
import { associatedRefundsSelector } from '../../recoil/selectors';
import { PosInventoryChange, WebWorkerActions } from '../../utils/types';
import { Step } from './refund-step';

interface PaymentName {
  name: string;
  type: PaymentMethodType;
  id: string;
}

const ReceiptDetails: React.FC = () => {
  const [selectedReceipt, setSelectedReceipt] =
    useRecoilState(selectedReceiptAtom);
  const { updateLatestReceipt, updateProductInventory, latestReceipt } =
    useOnReceiptCreated();

  const [triggerRefetch, setTriggerRefetch] =
    useRecoilState(triggerRefetchAtom);

  const associatedRefunds = useRecoilValue(associatedRefundsSelector);
  const store = useRecoilValue(storeAtom);
  const posDevice = useRecoilValue(posDeviceAtom);
  const user = useRecoilValue(userAtom);
  const setActions = useSetRecoilState(workerActionsAtom);
  const paymentTypes = useRecoilValue(paymentTypesAtom);

  const toast = useToast();

  // State to track if refund mode is active
  const [isRefundMode, setIsRefundMode] = useState<boolean>(false);

  // State to store refund quantities for each line item
  const [refundQuantities, setRefundQuantities] = useState<
    Record<string, number>
  >({});

  // State to store line items selected for refund
  const [refundLineItems, setRefundLineItems] = useState<LineItem[]>([]);

  // State for refund payments
  const [refundPayments, setRefundPayments] = useState<ReceiptPayment[]>([]);

  const canCreateRefund = selectedReceipt
    ? flatten(
        associatedRefunds.map((refund) =>
          refund.line_items.map((line_item) => line_item.line_quantity),
        ),
      ).reduce((acc, curr) => acc + curr, 0) <
      selectedReceipt.line_items.reduce(
        (acc, curr) => acc + curr.line_quantity,
        0,
      )
    : false;

  // Steps definition for the stepper
  const steps = [
    {
      title: 'Select Items',
    },
    {
      title: 'Select Payment',
    },
    {
      title: 'Review',
    },
  ];

  // Initialize the useStep hook
  const [currentStep, { goToNextStep, goToPrevStep, setStep, reset }] = useStep(
    {
      maxStep: steps.length - 1,
      initialStep: 0,
    },
  );

  const receiptTotal = selectedReceipt ? selectedReceipt.total_money : 0;

  const refundTotal = refundLineItems.reduce(
    (total, item) => total + item.total_money,
    0,
  );

  // Remaining amount to refund
  const totalAllocated = refundPayments.reduce(
    (acc, payment) => acc + payment.money_amount,
    0,
  );
  const remaining = Math.round(Math.max(refundTotal - totalAllocated, 0));

  // Calculate refunded quantities for each line item
  const refundedQuantities = React.useMemo(() => {
    if (!associatedRefunds) return {};

    const refundedQuantitiesMap: Record<string, number> = {};

    const flattenedLineItems = flatten(
      associatedRefunds.map((refund) => refund.line_items),
    );

    const groupedByItemId = groupBy(flattenedLineItems, 'item_id');

    Object.keys(groupedByItemId).forEach((itemId) => {
      const quantities = groupedByItemId[itemId].map(
        (item) => item.line_quantity,
      );
      refundedQuantitiesMap[itemId] = quantities.reduce(
        (acc, curr) => acc + curr,
        0,
      );
    });

    return refundedQuantitiesMap;
  }, [associatedRefunds]);

  useEffect(() => {
    setStep(0); // Reset the stepper on receipt change
  }, [selectedReceipt?.receipt_number]);

  // Effect to update refundLineItems based on refundQuantities
  useEffect(() => {
    if (!selectedReceipt) return;

    if (isRefundMode) {
      const items: LineItem[] = selectedReceipt.line_items
        .filter((item: LineItem) => (refundQuantities[item.id] || 0) > 0)
        .map((item: LineItem) => {
          const refundQuantity = refundQuantities[item.id];

          // Calculate per-unit amounts
          const perUnitQuantity = item.fraction ?? 1;
          const perUnitTotalMoney = item.total_money / item.line_quantity;
          const perUnitGrossTotalMoney =
            item.gross_total_money / item.line_quantity;
          const perUnitCostTotal = item.cost_total / item.line_quantity;
          const perUnitTotalDiscount = item.total_discount / item.line_quantity;

          const total_money = perUnitTotalMoney * refundQuantity;
          const gross_total_money = perUnitGrossTotalMoney * refundQuantity;
          const cost_total = perUnitCostTotal * refundQuantity;
          const total_discount = perUnitTotalDiscount * refundQuantity;

          // Proportionally adjust line_discounts
          const line_discounts = item.line_discounts.map((discount) => {
            const perUnitDiscountAmount =
              discount.money_amount / item.line_quantity;
            const money_amount = perUnitDiscountAmount * refundQuantity;
            return { ...discount, money_amount };
          });

          // Proportionally adjust line_taxes
          const line_taxes = item.line_taxes.map((tax) => {
            const perUnitTaxAmount = tax.money_amount / item.line_quantity;
            const money_amount = perUnitTaxAmount * refundQuantity;
            return { ...tax, money_amount };
          });

          // Create the refund line item
          return {
            ...item,
            id: ulid(), // Assign new ID for the refund line item
            quantity: refundQuantity * perUnitQuantity,
            line_quantity: refundQuantity,
            total_money,
            gross_total_money,
            cost_total,
            total_discount,
            line_discounts,
            line_taxes,
            confirmed_quantity: 0,
            confirmed_quantity_traceback: [],
            missing: {
              ...item.missing,
              quantity: 0,
            },
            missing_quantity_traceback: [],
            is_confirmed: false,
          };
        });
      setRefundLineItems(items);
    } else {
      setRefundLineItems([]);
    }
  }, [refundQuantities, selectedReceipt, isRefundMode]);

  // Handler for changing refund quantities
  const handleRefundQuantityChange = (id: string, value: number) => {
    setRefundQuantities((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // Handler to activate refund mode
  const handleCreateRefund = () => {
    setIsRefundMode(true);
  };

  // Handler to cancel refund mode
  const handleCancelRefund = () => {
    setIsRefundMode(false);
    setRefundQuantities({});
    setRefundPayments([]);
    reset(); // Reset the stepper
  };

  // Handler to submit refunds
  const handleSubmitRefund = async () => {
    if (!selectedReceipt || !store || !posDevice || !user) {
      toast({
        title: 'Missing required information for refund',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Validate that the refund payments cover the refund total
    if (remaining > 0) {
      toast({
        title: 'Payment total does not match refund total',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Generate a new receipt number
      let numericReceiptNumber = 1000;

      if (latestReceipt && latestReceipt.numericReceiptNumber) {
        numericReceiptNumber = latestReceipt.numericReceiptNumber + 1;
      }

      const receiptNumber = getReceiptId({
        posPrefix: posDevice.prefix,
        receiptNumber: numericReceiptNumber,
      });

      // Determine confirmation type
      const confirmation_type = ConfirmationType.HARD;

      // Create the refund receipt
      const refundReceipt: Receipt = {
        receipt_number: receiptNumber,
        custom_receipt_number: receiptNumber,
        store_id: store.id,
        pos_device_id: posDevice.id,
        customer_id: selectedReceipt.customer_id,
        customer_updated_traceback: [],
        employee_id: user.id,
        employee_name: user.name,
        pos_device_name: posDevice.name,
        customer_name: selectedReceipt.customer_name,
        dining_option: 'IN_STORE',
        has_debt: false,
        failed_checks: [],
        associated_refunds: [],
        client: selectedReceipt.client,
        comments: [],
        created_at: new Date().toISOString(),
        confirmation_type,
        line_items: refundLineItems,
        payments: refundPayments,
        is_confirmable: false,
        merchant_id: store.merchant_id,
        points_balance: selectedReceipt.points_balance,
        points_deducted: 0, // Adjust if points are refunded
        points_earned: 0,
        receipt_status: ReceiptStatus.UNCONFIRMED,
        receipt_type: ReceiptType.REFUND,
        total_money: refundTotal,
        updated_at: new Date().toISOString(),
        source: 'POS',
        surcharge: 0,
        threshold: selectedReceipt.threshold,
        tip: 0,
        total_discount: refundLineItems.reduce(
          (acc, item) => acc + item.total_discount,
          0,
        ),
        total_taxes: refundLineItems.flatMap((item) => item.line_taxes),
        total_discounts: refundLineItems.flatMap((item) => item.line_discounts),
        total_receipt_items: refundLineItems.length,
        total_tax: refundLineItems.reduce(
          (acc, item) =>
            acc +
            item.line_taxes.reduce(
              (taxAcc, tax) => taxAcc + tax.money_amount,
              0,
            ),
          0,
        ),
        customer: selectedReceipt.customer,
        refund_for: selectedReceipt.receipt_number, // Link to the original receipt
        order: selectedReceipt.order,
        cancelled_at: null,
        waiter: selectedReceipt.waiter,
      };

      // Save the refund receipt to the local database
      await db.offlineReceipts.put(refundReceipt);

      // Update the associated_refunds field of the original receipt

      const updatedReceipt: Receipt = {
        ...selectedReceipt,
        associated_refunds: [
          ...selectedReceipt.associated_refunds,
          receiptNumber,
        ],
        updated_at: new Date().toISOString(), // Update the timestamp
      };

      // Save the updated receipt to the local database. Find which table it is in, the online or offline receipts, then update it in that one

      const onlineReceipt = await db.onlineReceipts.get([
        store.id,
        selectedReceipt.receipt_number,
      ]);

      if (onlineReceipt) {
        await db.onlineReceipts.put(updatedReceipt);
      } else {
        await db.offlineReceipts.put(updatedReceipt);
      }

      setSelectedReceipt(updatedReceipt);

      const inventoryChanges: PosInventoryChange[] = refundLineItems.map(
        (item) => ({
          store_id: store.id,
          inventory_change: item.quantity,
          product_id: item.item_id,
        }),
      );

      updateLatestReceipt();

      // Update the inventory
      await updateProductInventory(inventoryChanges);

      // Trigger synchronization actions
      setActions([
        WebWorkerActions.SYNC_OFFLINE_RECEIPTS,
        WebWorkerActions.SYNC_ONLINE_RECEIPTS,
      ]);

      toast({
        title: 'Refund Receipt Created Successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Reset the refund mode and selections
      setIsRefundMode(false);
      setRefundQuantities({});
      setRefundPayments([]);
      reset(); // Reset the stepper
      setTriggerRefetch(!triggerRefetch);
    } catch (error) {
      toast({
        title: 'Error creating refund receipt',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      console.error('Error creating refund receipt:', error);
    }
  };

  // Payment types
  const sortedPaymentTypes = [...paymentTypes].sort((a) =>
    a.payment_type === PaymentMethodType.CASH ? -1 : 0,
  );

  useEffect(() => {
    setIsRefundMode(false); // Reset refund mode on receipt change
  }, [selectedReceipt?.receipt_number]);

  // Validation conditions
  const canProceedToStep2 = refundLineItems.length > 0;
  const canProceedToStep3 = Math.round(remaining) === 0;

  // Early return if no receipt is selected
  if (!selectedReceipt) {
    return null;
  }

  return (
    <VStack
      mt={10}
      borderRadius={0}
      width={'full'}
      p={5}
      height={'100%'}
      maxH={'100vh'}
      overflowY="auto">
      <Stack
        width="full"
        justify={{
          base: 'center', // Center items vertically when column
          md: 'space-between', // Space items in row when horizontal
        }}
        align="center" // Center items horizontally
        direction={{
          base: 'column', // Vertical stack for smaller screens
          md: 'row', // Horizontal stack for larger screens
        }}>
        <Heading size="sm">{selectedReceipt.receipt_number}</Heading>
        <Heading color={colors.primary} size="sm">
          {formatCurrency(selectedReceipt.total_money)}
        </Heading>
        <Badge
          variant="outline"
          width="130px"
          colorScheme={
            selectedReceipt.receipt_type === ReceiptType.SALE
              ? 'green'
              : 'purple'
          }>
          <Heading textAlign={'center'} size="sm">
            {selectedReceipt.receipt_type}
          </Heading>
        </Badge>
      </Stack>

      <Stack width={'full'} spacing="2">
        {/* Receipt Meta Information */}
        <VStack width="full" align="start" justify={'flex-start'}>
          {selectedReceipt.refund_for && (
            <HStack width="full">
              <Text fontWeight={'bold'}>Refund For:</Text>
              <Spacer />
              <Badge variant="outline" colorScheme="green">
                {selectedReceipt.refund_for}
              </Badge>
            </HStack>
          )}
          {selectedReceipt.associated_refunds.length > 0 && (
            <HStack width={'full'} maxW={'full'} justify={'space-between'}>
              <Text size="xs" fontWeight={'bold'}>
                Refunds{' '}
                <Text as="span" color={'gray'}>
                  {' '}
                  &times; {selectedReceipt.associated_refunds.length}{' '}
                </Text>
              </Text>
              <HStack
                overflowX={'scroll'}
                maxWidth={{
                  base: '200px',
                  md: '300px',
                }}
                spacing={'0.5'}>
                {selectedReceipt.associated_refunds.map((refund: string) => (
                  <Badge key={refund} variant={'outline'} colorScheme="purple">
                    {refund}
                  </Badge>
                ))}
              </HStack>
            </HStack>
          )}
          <HStack width="full">
            <Text fontWeight={'bold'}>Customer:</Text>
            <Text>{selectedReceipt.customer_name}</Text>
            <Spacer />

            <Text>
              Created{' '}
              {moment(selectedReceipt.created_at).format(
                'ddd, DD MMM YY @ HH:MM',
              )}{' '}
            </Text>
          </HStack>
          {!isRefundMode && (
            <HStack width="full">
              <Text fontWeight={'bold'}>Employee: </Text>
              <Text>{selectedReceipt.employee_name}</Text>
              <Spacer />
              <Text>
                Updated{' '}
                {moment(selectedReceipt.created_at).format(
                  'ddd, DD MMM YY @ HH:MM',
                )}{' '}
              </Text>
            </HStack>
          )}
          {selectedReceipt.note && !isRefundMode && (
            <HStack bg="green.100" p={2} borderRadius="md">
              <Text>{selectedReceipt.note}</Text>
            </HStack>
          )}
        </VStack>

        {/* Line Items */}
        {!isRefundMode && (
          <>
            <Divider />
            <Box maxH="50%" overflowY={'auto'}>
              <VStack align="stretch" spacing={2}>
                {selectedReceipt.line_items.map((item: LineItem) => {
                  const refundedQuantity =
                    refundedQuantities[item.item_id] || 0;

                  return (
                    <HStack key={item.id} justify={'space-between'}>
                      <Text>
                        {item.line_quantity} &times; {item.item_name}
                      </Text>
                      {refundedQuantity > 0 && (
                        <Text fontSize={'sm'} as="span" color="gray.500">
                          {' '}
                          <strong>
                            {refundedQuantity}/{item.line_quantity}{' '}
                          </strong>
                          Refunded Already
                        </Text>
                      )}
                      <Text>{formatCurrency(item.total_money)}</Text>
                    </HStack>
                  );
                })}
              </VStack>
            </Box>
            <Divider />
          </>
        )}

        {/* Points Information */}
        {!isRefundMode && (
          <VStack color={colors.primary} align="start" justify={'flex-start'}>
            <HStack width="full" justify={'space-between'}>
              <Text fontWeight={'bold'}>Points Earned </Text>
              <Text>
                {selectedReceipt.points_earned
                  ? selectedReceipt.points_earned
                  : 0}
              </Text>
            </HStack>
            <HStack width="full" justify={'space-between'}>
              <Text fontWeight={'bold'}>Points Balance</Text>
              <Text>{selectedReceipt.points_balance}</Text>
            </HStack>
            {selectedReceipt.note && (
              <HStack bg="green.100" p={2} borderRadius="md">
                <Text>{selectedReceipt.note}</Text>
              </HStack>
            )}
          </VStack>
        )}

        {/* Refund Process */}
        {isRefundMode && (
          <>
            {/* Stepper */}
            <Stack
              mt={10}
              direction={{ base: 'column', md: 'row' }}
              spacing="2">
              {steps.map((step, id) => (
                <Step
                  key={id}
                  cursor="pointer"
                  onClick={() => {
                    if (
                      (currentStep === 0 && canProceedToStep2) ||
                      (currentStep === 1 && canProceedToStep3) ||
                      currentStep === steps.length - 1
                    ) {
                      setStep(id);
                    }
                  }}
                  title={step.title}
                  isActive={currentStep === id}
                  isCompleted={currentStep > id}
                />
              ))}
            </Stack>

            {/* Content of the current step */}
            {currentStep === 0 && (
              <RefundStep1
                selectedReceipt={selectedReceipt}
                refundQuantities={refundQuantities}
                handleRefundQuantityChange={handleRefundQuantityChange}
                refundedQuantities={refundedQuantities}
              />
            )}
            {currentStep === 1 && (
              <RefundStep2
                refundTotal={refundTotal}
                remaining={remaining}
                totalAllocated={totalAllocated}
                refundPayments={refundPayments}
                setRefundPayments={setRefundPayments}
                sortedPaymentTypes={sortedPaymentTypes}
              />
            )}
            {currentStep === 2 && (
              <RefundStep3
                refundLineItems={refundLineItems}
                refundPayments={refundPayments}
                refundTotal={refundTotal}
                paymentTypes={paymentTypes}
              />
            )}

            {/* Navigation Buttons */}
            <HStack m={5} width={'full'} justify={'space-around'}>
              {currentStep > 0 && (
                <Button
                  borderRadius={'full'}
                  bg={colors.blue}
                  color={'white'}
                  onClick={goToPrevStep}>
                  Previous
                </Button>
              )}

              <Button
                borderRadius={'full'}
                bg={colors.red}
                color={'white'}
                onClick={handleCancelRefund}>
                Cancel
              </Button>
              {currentStep < steps.length - 1 && (
                <Button
                  borderRadius={'full'}
                  bg={colors.blue}
                  color={'white'}
                  onClick={goToNextStep}
                  isDisabled={
                    (currentStep === 0 && !canProceedToStep2) ||
                    (currentStep === 1 && !canProceedToStep3)
                  }>
                  Next
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button
                  borderRadius={'full'}
                  bg={colors.blue}
                  color={'white'}
                  onClick={handleSubmitRefund}
                  isDisabled={refundLineItems.length === 0 || remaining > 0}>
                  Complete
                </Button>
              )}
            </HStack>
          </>
        )}

        {/* Receipt Total */}
        {!isRefundMode && (
          <Stack direction="row" justify="space-between">
            <Text fontSize="lg" fontWeight="semibold" color={'gray.700'}>
              Receipt Total
            </Text>
            <Text fontSize="xl" fontWeight="semibold" color={'black'}>
              {formatCurrency(receiptTotal)}
            </Text>
          </Stack>
        )}

        {/* Refund Buttons */}
        {selectedReceipt.receipt_type === ReceiptType.SALE && !isRefundMode && (
          <HStack m={5} width={'full'} justify={'space-around'} spacing={4}>
            <Button
              borderRadius={'full'}
              bg={colors.blue}
              color={'white'}
              isDisabled={!canCreateRefund}
              onClick={handleCreateRefund}>
              Create Refund
            </Button>
          </HStack>
        )}
      </Stack>
    </VStack>
  );
};

// Step 1 Component: Select Items
const RefundStep1: React.FC<{
  selectedReceipt: Receipt;
  refundQuantities: Record<string, number>;
  handleRefundQuantityChange: (id: string, value: number) => void;
  refundedQuantities: Record<string, number>;
}> = ({
  selectedReceipt,
  refundQuantities,
  handleRefundQuantityChange,
  refundedQuantities,
}) => (
  <Box maxH="50%" overflowY={'auto'}>
    <VStack align="stretch" spacing={2}>
      {selectedReceipt.line_items
        .filter((item) => {
          const refundedQuantity = refundedQuantities[item.item_id] || 0;
          const remainingQuantity = item.line_quantity - refundedQuantity;
          return remainingQuantity > 0;
        })
        .map((item: LineItem) => {
          const refundedQuantity = refundedQuantities[item.item_id] || 0;
          const remainingQuantity = item.line_quantity - refundedQuantity;
          return (
            <HStack key={item.id} justify={'space-between'}>
              <Text>
                {remainingQuantity} &times; {item.item_name}
              </Text>
              {
                // Display the refunded quantity if any
                refundedQuantity > 0 && (
                  <Text fontSize={'sm'} as="span" color="gray.500">
                    {' '}
                    <strong>
                      {refundedQuantity}/{item.line_quantity}{' '}
                    </strong>
                    Refunded Already
                  </Text>
                )
              }
              <HStack>
                <NumberInput
                  min={0}
                  size="md"
                  borderRadius={'full'}
                  max={remainingQuantity}
                  value={refundQuantities[item.id] || 0}
                  onChange={(valueString: string) =>
                    handleRefundQuantityChange(
                      item.id,
                      parseInt(valueString) || 0,
                    )
                  }
                  width="100px">
                  <NumberInputField bg="white" borderRadius={'full'} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Button
                  size="sm"
                  colorScheme="purple"
                  borderRadius={'full'}
                  onClick={() => {
                    handleRefundQuantityChange(item.id, remainingQuantity);
                  }}>
                  All
                </Button>
              </HStack>
            </HStack>
          );
        })}
    </VStack>
  </Box>
);

// Step 2 Component: Select Payment
const RefundStep2: React.FC<{
  refundTotal: number;
  remaining: number;
  totalAllocated: number;
  refundPayments: ReceiptPayment[];
  setRefundPayments: React.Dispatch<React.SetStateAction<ReceiptPayment[]>>;
  sortedPaymentTypes: PaymentType[];
}> = ({
  refundTotal,
  remaining,
  totalAllocated,
  refundPayments,
  setRefundPayments,
  sortedPaymentTypes,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // Payment types names
  const paymentTypesNames: PaymentName[] = [
    ...sortedPaymentTypes.map((p) => ({
      name: p.name,
      type: p.payment_type,
      id: p.id,
    })),
    { name: 'Split', type: PaymentMethodType.OTHER, id: 'split' },
  ];

  return (
    <VStack spacing="4" align="stretch">
      <VStack width="full" spacing="4">
        <HStack width="full" justify="space-between">
          <Text fontSize="lg" fontWeight="medium">
            Refund Total
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {formatCurrency(refundTotal)}
          </Text>
        </HStack>
        <HStack width="full" justify="space-between">
          <Text fontSize="lg" fontWeight="medium">
            Remaining
          </Text>
          <Text
            color={remaining === 0 ? 'green.500' : 'orange.500'}
            fontSize="lg"
            fontWeight="bold">
            {formatCurrency(remaining)}
          </Text>
        </HStack>
        <HStack width="full" justify="space-between">
          <Text fontSize="lg" fontWeight="medium">
            Allocated
          </Text>
          <Text
            color={totalAllocated === refundTotal ? 'green.500' : 'red.500'}
            fontSize="lg"
            fontWeight="bold">
            {formatCurrency(totalAllocated)}
          </Text>
        </HStack>
      </VStack>
      <Tabs
        isFitted
        width="full"
        color={colors.primary}
        size={'lg'}
        index={selectedTabIndex}
        onChange={(index) => setSelectedTabIndex(index)}>
        <TabList>
          {paymentTypesNames.map((name, index) => (
            <Tab fontWeight={'semibold'} key={index + name.type}>
              {name.name}
            </Tab>
          ))}
        </TabList>
        <TabIndicator />
        <TabPanels>
          {sortedPaymentTypes.map((paymentType) => {
            const existingPayment = refundPayments.find(
              (p) => p.payment_type_id === paymentType.id,
            );

            return (
              <TabPanel key={paymentType.id}>
                <FormControl
                  padding={'2'}
                  key={paymentType.id}
                  id={`payment-${paymentType.id}`}>
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: '1.5', md: '2' }}
                    justify="space-between">
                    <FormLabel variant="inline">
                      <Heading size="sm">{paymentType.name}</Heading>
                    </FormLabel>
                    <NumberInput
                      maxW={{ md: '3xl' }}
                      size={'md'}
                      isDisabled={remaining <= 0}
                      defaultValue={existingPayment?.money_amount || ''}
                      onChange={(valueString) => {
                        const amount = parseFloat(valueString) || 0;

                        const newPayment: ReceiptPayment = {
                          payment_type_id: paymentType.id,
                          money_amount: amount,
                          note: existingPayment?.note || '',
                          created_at:
                            existingPayment?.created_at ||
                            new Date().toISOString(),
                          updated_at: new Date().toISOString(),
                        };

                        setRefundPayments((prevPayments) => {
                          const otherPayments = prevPayments.filter(
                            (p) => p.payment_type_id !== paymentType.id,
                          );
                          if (amount > 0) {
                            return [...otherPayments, newPayment];
                          } else {
                            return otherPayments;
                          }
                        });
                      }}>
                      <NumberInputField borderRadius={'full'} bg="white" />
                    </NumberInput>
                  </Stack>
                  <Input
                    mt="2"
                    borderRadius={'full'}
                    placeholder="Add a note (optional)"
                    value={existingPayment?.note || ''}
                    onChange={(e) => {
                      const note = e.target.value;
                      if (existingPayment && existingPayment.money_amount > 0) {
                        const updatedPayment: ReceiptPayment = {
                          ...existingPayment,
                          note: note,
                          updated_at: new Date().toISOString(),
                        };

                        setRefundPayments((prevPayments) => {
                          return prevPayments.map((p) =>
                            p.payment_type_id ===
                            existingPayment.payment_type_id
                              ? updatedPayment
                              : p,
                          );
                        });
                      }
                    }}
                    isDisabled={
                      !existingPayment || existingPayment.money_amount <= 0
                    }
                  />
                </FormControl>
              </TabPanel>
            );
          })}

          {/* Split Payment Tab */}
          <TabPanel>
            <VStack spacing={'1'} maxH="400px" width={'full'} overflowY="auto">
              {sortedPaymentTypes.map((paymentType, index) => {
                const existingPayment = refundPayments.find(
                  (p) => p.payment_type_id === paymentType.id,
                );

                return (
                  <FormControl
                    padding={'2'}
                    key={paymentType.id}
                    id={`payment-${paymentType.id}`}>
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      spacing={{ base: '1.5', md: '2' }}
                      justify="space-between">
                      <FormLabel variant="inline">
                        {' '}
                        <Heading size="sm">{paymentType.name}</Heading>
                      </FormLabel>
                      <NumberInput
                        size="md"
                        maxW={{ md: '3xl' }}
                        isDisabled={remaining <= 0}
                        defaultValue={existingPayment?.money_amount || ''}
                        onChange={(valueString) => {
                          const amount = parseFloat(valueString) || 0;

                          const newPayment: ReceiptPayment = {
                            payment_type_id: paymentType.id,
                            money_amount: amount,
                            note: existingPayment?.note || '',
                            created_at:
                              existingPayment?.created_at ||
                              new Date().toISOString(),
                            updated_at: new Date().toISOString(),
                          };

                          setRefundPayments((prevPayments) => {
                            const otherPayments = prevPayments.filter(
                              (p) => p.payment_type_id !== paymentType.id,
                            );
                            if (amount > 0) {
                              return [...otherPayments, newPayment];
                            } else {
                              return otherPayments;
                            }
                          });
                        }}>
                        <NumberInputField bg="white" borderRadius={'full'} />
                      </NumberInput>
                    </Stack>

                    <Input
                      mt="2"
                      borderRadius={'full'}
                      placeholder="Add a note (optional)"
                      value={existingPayment?.note || ''}
                      onChange={(e) => {
                        const note = e.target.value;
                        if (
                          existingPayment &&
                          existingPayment.money_amount > 0
                        ) {
                          const updatedPayment: ReceiptPayment = {
                            ...existingPayment,
                            note: note,
                            updated_at: new Date().toISOString(),
                          };

                          setRefundPayments((prevPayments) => {
                            return prevPayments.map((p) =>
                              p.payment_type_id ===
                              existingPayment.payment_type_id
                                ? updatedPayment
                                : p,
                            );
                          });
                        }
                      }}
                      isDisabled={
                        !existingPayment || existingPayment.money_amount <= 0
                      }
                    />

                    {index < sortedPaymentTypes.length - 1 && <Divider m={4} />}
                  </FormControl>
                );
              })}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

// Step 3 Component: Review
const RefundStep3: React.FC<{
  refundLineItems: LineItem[];
  refundPayments: ReceiptPayment[];
  refundTotal: number;
  paymentTypes: PaymentType[];
}> = ({ refundLineItems, refundPayments, refundTotal, paymentTypes }) => (
  <VStack spacing="2" align="stretch">
    {/* Refund Items */}
    <Box>
      <VStack align="stretch" spacing={2}>
        {refundLineItems.map((item: LineItem) => (
          <HStack key={item.id} justify={'space-between'}>
            <Text>
              {item.line_quantity} &times; {item.item_name}
            </Text>

            <Text>{formatCurrency(item.total_money)}</Text>
          </HStack>
        ))}
      </VStack>
    </Box>
    <Divider />
    {/* Payments */}
    <VStack spacing="2">
      {refundPayments.map((payment, index) => {
        const paymentType = paymentTypes.find(
          (p) => p.id === payment.payment_type_id,
        );
        return (
          <HStack key={index} width={'full'} justify="space-between">
            <Text fontSize="lg" fontWeight="semibold" color={'gray.700'}>
              {paymentType ? paymentType.name : 'Unknown'}
            </Text>
            {payment.note && (
              <Text fontSize={'small'} color={'fg.muted'}>
                {payment.note}
              </Text>
            )}
            <Text>{formatCurrency(payment.money_amount)}</Text>
          </HStack>
        );
      })}
    </VStack>
    <Divider />
    {/* Refund Total */}
    <HStack width="full" justify="space-between">
      <Spacer />
      <Text fontSize="lg" fontWeight="semibold" color={'gray.700'}>
        TOTAL:
      </Text>
      <Text fontSize="xl" fontWeight="semibold" color={'black'}>
        {formatCurrency(refundTotal)}
      </Text>
    </HStack>
  </VStack>
);

export default ReceiptDetails;
