import {
  CalculateDiscountCommands,
  CalculateDiscountResult,
} from '../types/discounts.types';

export class DiscountCalculator {
  // Method to calculate percentage discount
  static calculatePercentageDiscount(
    itemPrice: number,
    discountPercent: number,
  ): CalculateDiscountResult {
    const discountValue = itemPrice * (discountPercent / 100);
    const finalPrice = itemPrice - discountValue;
    return {
      discount_command: CalculateDiscountCommands.PERCENTAGE,
      item_price: itemPrice,
      final_price: parseFloat(finalPrice.toFixed(2)),
      discount_value: parseFloat(discountValue.toFixed(2)),
    };
  }

  // Method to calculate fixed amount discount
  static calculateFixedDiscount(
    itemPrice: number,
    discountAmount: number,
  ): CalculateDiscountResult {
    const finalPrice = itemPrice - discountAmount;
    return {
      discount_command: CalculateDiscountCommands.FIXED,
      item_price: itemPrice,
      final_price: parseFloat(finalPrice.toFixed(2)),
      discount_value: parseFloat(discountAmount.toFixed(2)),
    };
  }

  // Method to calculate multiple discounts (percentage + fixed)
  static calculateMultipleDiscounts(
    itemPrice: number,
    discountPercent: number,
    discountAmount: number,
  ): CalculateDiscountResult {
    const percentageDiscount = itemPrice * (discountPercent / 100);
    const totalDiscount = percentageDiscount + discountAmount;
    const finalPrice = itemPrice - totalDiscount;
    return {
      discount_command: CalculateDiscountCommands.MULTIPLE_DISCOUNTS,
      item_price: itemPrice,
      final_price: parseFloat(finalPrice.toFixed(2)),
      discount_value: parseFloat(totalDiscount.toFixed(2)),
      total_discount: parseFloat(totalDiscount.toFixed(2)),
    };
  }

  // Method to apply item-level discounts
  static calculateItemLevelDiscounts(
    items: { price: number; discountPercent: number }[],
  ): CalculateDiscountResult {
    const discountItems = items.map((item) => {
      const discountValue = item.price * (item.discountPercent / 100);
      const finalPrice = item.price - discountValue;
      return {
        price: item.price,
        finalPrice: parseFloat(finalPrice.toFixed(2)),
        discount_value: parseFloat(discountValue.toFixed(2)),
        discount_ids: [],
      };
    });
    return {
      discount_command: CalculateDiscountCommands.ITEM_LEVEL_DISCOUNT,
      items: discountItems,
    };
  }

  // Method to calculate receipt-level discount
  static calculateReceiptLevelDiscount(
    items: { price: number }[],
    discountPercent: number,
  ): CalculateDiscountResult {
    const totalOriginalPrice = items.reduce((sum, item) => sum + item.price, 0);
    const discountValue = totalOriginalPrice * (discountPercent / 100);
    const finalPrice = totalOriginalPrice - discountValue;
    return {
      discount_command: CalculateDiscountCommands.RECEIPT_LEVEL_DISCOUNT,
      item_price: totalOriginalPrice,
      final_price: parseFloat(finalPrice.toFixed(2)),
      discount_value: parseFloat(discountValue.toFixed(2)),
      total_discount: parseFloat(discountValue.toFixed(2)),
    };
  }

  // Method to apply tax after discounts have been applied
  static calculateTaxAfterDiscount(
    finalPrice: number,
    taxRate: number,
  ): CalculateDiscountResult {
    const taxValue = finalPrice * (taxRate / 100);
    const totalPriceWithTax = finalPrice + taxValue;
    return {
      discount_command: CalculateDiscountCommands.TAX_AFTER_DISCOUNT,
      item_price: finalPrice,
      final_price: parseFloat(totalPriceWithTax.toFixed(2)),
      total_tax: parseFloat(taxValue.toFixed(2)),
    };
  }

  // Method to apply tax before discounts
  static calculateTaxBeforeDiscount(
    itemPrice: number,
    taxRate: number,
    discountPercent: number,
  ): CalculateDiscountResult {
    const taxValue = itemPrice * (taxRate / 100);
    const priceWithTax = itemPrice + taxValue;
    const discountValue = priceWithTax * (discountPercent / 100);
    const finalPrice = priceWithTax - discountValue;
    return {
      discount_command: CalculateDiscountCommands.TAX_BEFORE_DISCOUNT,
      item_price: itemPrice,
      final_price: parseFloat(finalPrice.toFixed(2)),
      discount_value: parseFloat(discountValue.toFixed(2)),
      total_tax: parseFloat(taxValue.toFixed(2)),
    };
  }
}
