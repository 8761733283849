import { useState, useEffect } from 'react';

function usePageVisibility(): boolean {
  const [isVisible, setIsVisible] = useState<boolean>(() => {
    // Check if document is defined to avoid errors during server-side rendering
    if (typeof document !== 'undefined') {
      return !document.hidden;
    }
    return true; // Default to true if document is not available
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      }
    };
  }, []);

  return isVisible;
}

export default usePageVisibility;
