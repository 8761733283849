import { Timestamps } from './shared.types';

/**
 * Enum representing the various payment method types.
 */
export enum PaymentMethodType {
  /** Cash payment */
  CASH = 'CASH',
  /** Card payment */
  CARD = 'CARD',
  /** Debt payment */
  DEBT = 'DEBT',
  /** Mobile money payment */
  MOBILE_MONEY = 'MOBILE_MONEY',
  /** Payment by cheque */
  CHEQUE = 'CHEQUE',
  /** Bank transfer */
  BANK_TRANSFER = 'BANK_TRANSFER',
  /** Coupon payment */
  COUPON = 'COUPON',
  /** Payment using loyalty points */
  LOYALTY_POINTS = 'LOYALTY_POINTS',
  /** Other types of payment */
  OTHER = 'OTHER',
}

/**
 * Represents a payment type in the Loyverse system.
 */
export interface PaymentType extends Timestamps {
  /**
   * The unique identifier of the payment type.
   * If included in a POST request, it will update the existing payment type instead of creating a new one.
   */
  id: string;

  /**
   * The name of the payment type.
   */
  name: string;

  /**
   * The payment method type.
   */
  payment_type: PaymentMethodType;

  /**
   * The list of store IDs where this payment type is available.
   */
  stores: string[];

  /**
   * The ID of the merchant who owns this payment type.
   */

  merchant_id: string;
}

/**
 * Represents a create payment type request.
 */

export interface CreatePaymentType {
  /**
   * The name of the payment type.
   */
  name: string;

  /**
   * The payment method type.
   */
  payment_type: PaymentMethodType;

  /**
   * The list of store IDs where this payment type is available.
   */
  stores: string[];

  /**
   * The ID of the merchant who owns this payment type.
   */

  merchant_id: string;
}

/**
 * Represents a payment type update request.
 */

export interface UpdatePaymentType {
  /**
   * The name of the payment type.
   */
  name?: string;

  /**
   * The payment method type.
   */
  payment_type?: PaymentMethodType;

  /**
   * The list of store IDs where this payment type is available.
   */
  add_stores?: string[];

  /**
   * The list of store IDs where this payment type is no longer available.
   */

  remove_stores?: string[];

  /**
   * The ID of the payment type to update.
   */

  id: string;
}

/**
 * Represents a payment type page
 */

export interface PaymentTypePage {
  /**
   * The list of payment types.
   */
  payment_types: PaymentType[];

  /**
   * The total number of payment types.
   */
  count: number;

  /**
   * The cursor for the next page of payment types.
   */

  cursor: string;
}

/**
 * Represents a Get Payment Type request.
 */

export interface GetPaymentType {
  /**
   * The ID of the payment type to get.
   */
  id: string;
}

/**
 * Represents a Get List of Payment Type request.
 */

export interface GetPaymentTypes {
  /**
   * The cursor for the next page of payment types.
   */

  cursor?: string;

  /**
   * The number of payment types to get.
   */

  limit?: number;

  /**
   * The ID of the merchant who owns this payment type.
   */

  merchant_id: string;

  /**
   * The list of store IDs where this payment type is available.
   */

  stores?: string[];
}

/**
 * Represents a Payment Type DB Item.
 */

export interface PaymentTypeDBItem extends Timestamps, PaymentType {
  /**
   * The Primary Key.
   */
  PK: string;

  /**
   * The Sort Key.
   */
  SK: string;

  /**
   * The 1st GSI PK.
   */
  GSI1PK: string;

  /**
   * The 1st GSI SK.
   */

  GSI1SK: string;

  /**
   * The Entity Index PK.
   */

  EntityIndexPK: string;

  /**
   * The Entity Index SK.
   */

  EntityIndexSK: string;
}
