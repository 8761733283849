import { uniqBy } from 'lodash';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { db } from '../api/local';
import {
  currentCartIdAtom,
  latestReceiptAtom,
  posDeviceAtom,
  productsAtom,
} from '../recoil/atoms';
import { LatestReceipt, PosInventoryChange } from '../utils/types';
import { getReceiptNumber, StoreProduct } from '@bofrak-backend/shared';

interface OnReceiptCreatedHook {
  latestReceipt: LatestReceipt | null;
  updateLatestReceipt: () => void;
  updateProductInventory: (changes: PosInventoryChange[]) => Promise<void>;
}

function useOnReceiptCreated(): OnReceiptCreatedHook {
  const [latestReceipt, setLatestReceipt] = useRecoilState(latestReceiptAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  const posDevice = useRecoilValue(posDeviceAtom);
  const cartId = useRecoilValue(currentCartIdAtom);

  useEffect(() => {
    updateLatestReceipt();
  }, [posDevice, cartId]);

  const updateLatestReceipt = async () => {
    if (!posDevice) {
      setLatestReceipt(null);
      return;
    }

    const { id: posDeviceId } = posDevice;

    try {
      const [offlineReceipts, onlineReceipts] = await Promise.all([
        db.offlineReceipts.where('pos_device_id').equals(posDeviceId).toArray(),
        db.onlineReceipts.where('pos_device_id').equals(posDeviceId).toArray(),
      ]);

      // Combine receipts from both sources
      const allReceipts = uniqBy(
        [...offlineReceipts, ...onlineReceipts],
        'receipt_number',
      );

      if (allReceipts.length === 0) {
        setLatestReceipt({
          receipt: null,
          isOffline: null,
          numericReceiptNumber: 0,
        });
        return;
      }

      // Parse receipt number to get the numeric part and sort by it
      const sortedReceipts = allReceipts
        .map((receipt) => ({
          receipt,
          numericReceiptNumber: getReceiptNumber(receipt.receipt_number),
          isOffline: offlineReceipts.includes(receipt),
        }))
        .sort((a, b) => b.numericReceiptNumber - a.numericReceiptNumber);

      // Set the most recent receipt
      setLatestReceipt({
        receipt: sortedReceipts[0].receipt,
        isOffline: sortedReceipts[0].isOffline,
        numericReceiptNumber: sortedReceipts[0].numericReceiptNumber,
      });
    } catch (error) {
      console.error('Error fetching receipts:', error);
      setLatestReceipt({
        receipt: null,
        isOffline: null,
        numericReceiptNumber: 0,
      });
    }
  };

  const updateProductInventory = async (changes: PosInventoryChange[]) => {
    const updatedProducts = [...products]; // Create a copy to avoid mutating state directly

    for (const change of changes) {
      const { store_id, inventory_change, product_id } = change;

      try {
        // Fetch the product from IndexedDB
        const product = await db.storeProducts.get([store_id, product_id]);

        if (product) {
          let parentProduct: StoreProduct | undefined | null = product;
          const isFraction = !product.is_parent;

          if (isFraction) {
            // The product is not a parent; get the parent product
            parentProduct = await db.storeProducts.get([
              store_id,
              product.parent_id,
            ]);

            if (!parentProduct) {
              console.warn(
                `Parent product not found for product ${product_id}`,
              );
              continue;
            }

            // Update the inventory of the current product
            product.inventory += inventory_change;

            // Save the updated product back to IndexedDB
            await db.storeProducts.put(product);

            // Update the product in the Recoil state
            const productIndex = updatedProducts.findIndex(
              (p) => p.store_id === store_id && p.id === product_id,
            );

            if (productIndex !== -1) {
              updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                inventory: product.inventory,
              };
            }
          }

          // Update the inventory of the parent product
          parentProduct.inventory += Number(inventory_change.toFixed(2));

          if (parentProduct.inventory < parentProduct.fraction) {
            parentProduct.is_available_for_sale = false;
          }

          // Save the updated parent product back to IndexedDB
          await db.storeProducts.put(parentProduct);

          // Update the parent product in the Recoil state
          const parentIndex = updatedProducts.findIndex(
            (p) => p.store_id === store_id && p.id === parentProduct.id,
          );

          if (parentIndex !== -1) {
            updatedProducts[parentIndex] = {
              ...updatedProducts[parentIndex],
              inventory: parentProduct.inventory,
            };
          }

          // Update the inventories of all fractions
          const fractions = await db.storeProducts
            .where('store_id')
            .equals(store_id)
            .and((p) => p.parent_id === parentProduct.id)
            .toArray();

          for (const fraction of fractions) {
            // Skip updating the current product if it's already updated
            if (isFraction && fraction.id === product_id) {
              continue;
            }

            fraction.inventory = parentProduct.inventory;

            if (fraction.inventory < fraction.fraction) {
              fraction.is_available_for_sale = false;
            }

            // Save the updated fraction back to IndexedDB
            await db.storeProducts.put(fraction);

            // Update the fraction in the Recoil state
            const fractionIndex = updatedProducts.findIndex(
              (p) => p.store_id === store_id && p.id === fraction.id,
            );

            if (fractionIndex !== -1) {
              updatedProducts[fractionIndex] = {
                ...updatedProducts[fractionIndex],
                inventory: fraction.inventory,
              };
            }
          }
        }
      } catch (error) {
        console.error(
          `Error updating inventory for product ${product_id}:`,
          error,
        );
      }
    }

    // Update the Recoil state with the new products list
    setProducts(updatedProducts);
  };

  return { latestReceipt, updateLatestReceipt, updateProductInventory };
}

export default useOnReceiptCreated;
