// useWorker.ts
import { useEffect, useRef, useState, useCallback } from 'react';

interface WorkerStatus {
  status: 'idle' | 'running' | 'progress' | 'success' | 'error';
  error?: string;
  message?: string;
}

interface UseWorkerOptions {
  action: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export function useWorker(WorkerConstructor: new () => Worker) {
  const [workerStatus, setWorkerStatus] = useState<WorkerStatus>({
    status: 'idle',
  });
  const workerRef = useRef<Worker | null>(null);

  const startWorker = useCallback(
    (options: UseWorkerOptions) => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }

      workerRef.current = new WorkerConstructor();

      workerRef.current.onmessage = (event) => {
        const { status, error, message } = event.data;
        setWorkerStatus({ status, error, message });
      };

      workerRef.current.onerror = (e) => {
        setWorkerStatus({ status: 'error', error: e.message });
      };

      setWorkerStatus({ status: 'running' });
      workerRef.current.postMessage({
        action: options.action,
        payload: options.payload,
      });
    },
    [WorkerConstructor],
  );

  useEffect(() => {
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
        setWorkerStatus({ status: 'idle' });
      }
    };
  }, []);

  return { workerStatus, startWorker };
}
